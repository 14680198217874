import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { reset, submit } from 'redux-form';

import { makeStyles } from '@material-ui/core/styles';

import CustomCard from '../../components/CustomCard';
import LoginForm from './LoginForm';
import { login } from '../../actions/app';
import {getCurrentUser} from '../../reducers/app';

const useStyles = makeStyles({
	root: {
		minWidth: 300,
		maxWidth: '40vw'
	},
	content: {
		composes: 'flexColumn center',
		padding: '30px 40px'
	}
});

const Login = (props) => {
	// styles
	const classes = useStyles(props);

	// dispatch
	const dispatch = useDispatch();

	// selectors
	const user = useSelector(getCurrentUser);

	if (user) {
		return null;
	}

	const _submit = () => dispatch(submit('login'));
	const _reset = () => dispatch(reset('login'));

	const _login = values => {
		dispatch(login(values.username, values.password));
	};

	return (
		<CustomCard
			title='Connexion'
			content={
				<LoginForm onSubmit={_login} />
			}
			rootClassName={classes.root}
			contentClassName={classes.content}
			cancelAction={_reset}
			okAction={_submit}
			okLabel='Se connecter'
		/>
	)
};

export default Login;
