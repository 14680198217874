import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import { updateCurrentOrderThunk } from '../actions/orders'

const useStyles = makeStyles({
	iframe: {
		width: '100%',
		height: 1,
		border: 'none',
		padding: 0,
		margin: 0,
		visibility: 'hidden'
	}
});

const PrintIframe = (props) => {
	// styles
	const classes = useStyles(props);

	const dispatch = useDispatch();

	const updateOrder = useCallback(() => {
		dispatch(updateCurrentOrderThunk());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	//---------------------------------------------------------//
	//-------------------- label printing ---------------------//
	//---------------------------------------------------------//
	useEffect(() => {
		/**
		 * print the pdf inside the iframe
		 * - if testing in local, use the build version of the BO
		 * so the server and the bo have the same domain and port: localhost:1337
		 * window.print works only for frames in same domain
		 * - window.print is not allowed in firefox
		 */
		function print() {
			const win = document.getElementById('print');
			//wait 1s to allow the pdf to load fully
			setTimeout(() => {
				win.contentWindow.print();
			}, 1000);
			updateOrder();
		}

		const printFrame = document.getElementById('print');
		const firefoxAgent = navigator.userAgent.indexOf("Firefox") > -1;
		if (printFrame && !firefoxAgent && window.location.origin !== 'http://localhost:3000') {
			printFrame.onload = print;
		}

		return () => {
			if (printFrame) {
				printFrame.onload = null;
			}
		}
	}, [updateOrder]);

	return (
		<iframe title='print' name='print' id="print" className={classes.iframe} scrolling='no' />
	)
};

export default PrintIframe;