import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

import FormField from '../../components/form/FormField';
import ReduxFormSelect from '../../components/reduxFormMUIWrapper/ReduxFormSelect';
import { STATES } from '../../constants';

const useStyles = makeStyles(theme => ({
	root: {
		minWidth: 450
	},
	rows: {
		composes: 'flexRow center'
	},
	field: {
		minWidth: 200,
		marginLeft: 10
	},
	stateSelect: {
		composes: '$field',
		minWidth: 150
	},
	dateTimeField: {
		composes: '$field',
		maxWidth: 200,
	},
}));

const SearchForm = (props) => {
	// state
	const [selectedState, setSelectedState] = useState([]);

	// props
	const { handleSubmit, resetForm } = props;

	// styles
	const classes = useStyles(props);

	// dispatch
	const dispatch = useDispatch();

	useEffect(() => {
		if (resetForm) {
			setSelectedState([]);
		}

		return () => {
			setSelectedState([]);
		}
	}, [resetForm]);

	const _handleDateChange = (event, newValue) => {
		dispatch(change('searchForm', 'expeditionDate', new Date(newValue)));
	};

	const _handleStateChange = (event, newValue) => {
		setSelectedState(newValue);
	};
	const _renderSelectItem = (selected) => {
		return selected.map(value => STATES[value]).join(', ');
	};

	return (
		<form onSubmit={handleSubmit} className={classes.root}>
			<div className={classes.rows}>
				<FormField
					label='Statut'
					name='state'
					multiple
					value={selectedState}
					onChange={_handleStateChange}
					component={ReduxFormSelect}
					containerClassName={classes.stateSelect}
					valueClassName={classes.stateSelect}
					renderValue={_renderSelectItem}
				>
					{ Object.keys(STATES).map(key =>
						<MenuItem key={key} value={key}>
							<Checkbox checked={selectedState.indexOf(key) > -1} />
							<ListItemText primary={STATES[key]} />
						</MenuItem>
					)}
				</FormField>
			</div>

			<div className={classes.rows}>
				<FormField
					label='Nom'
					name='lastName'
					containerClassName={classes.field}
					valueClassName={classes.field}
				/>
				<FormField
					label='Prénom'
					name='firstName'
					containerClassName={classes.field}
					valueClassName={classes.field}
				/>
			</div>

			<div className={classes.rows}>
				<FormField
					label='Numéro de commande'
					name='orderId'
					containerClassName={classes.field}
					valueClassName={classes.field}
				/>
				<FormField
					label="Date d'expédition"
					name='expeditionDateStr'
					type='datetime-local'
					onChange={_handleDateChange}
					valueClassName={classes.dateTimeField}
					containerClassName={classes.dateTimeField}
				/>
			</div>

			<input type='submit' />
		</form>
	)
};

SearchForm.propTypes = {
	onSubmit: PropTypes.func,
	resetForm: PropTypes.bool
};
export default reduxForm({
	form: 'searchForm'
})(SearchForm);