import { filter, sort, capitalizeCase, isNull } from './utils';

/**
 * get values from parseObject
 * @param {Parse.Object} parseObject
 * @param {Array|Set} names
 * @param [dest]
 */
export function getValues(parseObject, names, dest = {}) {
	if (parseObject == null) {
		// makes it work with redux-form initialValues
		return null;
	}
	names.forEach(name => {
		const value = parseObject.get(name);
		if (value != null) {
			dest[name] = value;
		}
	});
	return dest;
}

/**
 * . null or undefined values aren't set
 * . a value is set only when it's different
 * @param parseObject
 * @param values
 * @param {Array|Set} names (optional), ensures we only set the right properties
 */
export function setValues(parseObject, values, names) {
	if (names) {
		values = filter(values, names);
	}
	for (let key in values) {
		if (!values.hasOwnProperty(key)) {
			continue;
		}
		const value = values[key];
		setValue(parseObject, key, value);
	}
}

export function setValue(parseObject, name, value) {
	const oldValue = parseObject.get(name);
	if (isNull(value)) {
		parseObject.unset(name);
	} else if (oldValue !== value) {
		parseObject.set(name, value);
	}
}

export function equals(obj1, obj2) {
	if (obj1 == null) {
		return obj2 == null;
	}
	if (obj2 == null) {
		return false;
	}
	return obj1.id === obj2.id;
}

//-------------------------------------------------------------------------//
//------------------------------ User utils -------------------------------//
//-------------------------------------------------------------------------//
export function getUserName(user) {
	if (!user) return '';
	return getFirstName(user) + ' ' + getLastName(user);
}
export function getFirstName(user) {
	if (!user) return null;
	const firstName = user.get('firstName') || '';
	return capitalizeCase(firstName);
}
export function getLastName(user) {
	if (!user) return null;
	const lastName = user.get('lastName') || '';
	return lastName.toUpperCase();
}
export function getFullName(user) {
	if (!user) return null;
	return getFirstName(user) + " " + getLastName(user);
}
export function getInverseFullName(user) {
	if (!user) return null;
	return getLastName(user) + " " + getFirstName(user);
}

//-------------------------------------------------------------------------//
//-------------------------------- sorting --------------------------------//
//-------------------------------------------------------------------------//
//--------------------------------//
//------------ orders ------------//
//--------------------------------//
const orderSortKeySupplier = order => {
	const plan = order.get('date');
	const user = plan ? plan.get('user') : null;
	return user ? getFullName(user).toLowerCase() : order.id.toLowerCase();
};
export function sortOrders(array) {
	sort(array, orderSortKeySupplier);
}

export function toId(parseObjOrId) {
	if (!parseObjOrId) {
		return null;
	}
	if (typeof parseObjOrId === 'string') {
		return parseObjOrId;
	}
	return parseObjOrId.id;
}

/**
 * compares by id
 * @param parseObjects
 * @param searchedParseObject
 * @returns {boolean}
 */
export function includes(parseObjects, searchedParseObject) {
	if (!parseObjects || !searchedParseObject) {
		return false;
	}
	const searchedId = searchedParseObject.id;
	return !!parseObjects.find(obj => obj.id === searchedId);
}

/**
 * @param {Array} parseObjects
 * @param {Array} excludedObjects
 * @return {Array} a filtered copy
 */
export function exclude(parseObjects, excludedObjects) {
	const excludedIds = new Set();
	excludedObjects.forEach(excludedObject => excludedIds.add(excludedObject.id));
	return parseObjects.filter(parseObject => !excludedIds.has(parseObject.id));
}

/**
 * get distinct of objects
 * @param {Array<Parse.Object>} parseObjects
 * @param {string} [key], it can be 'username', 'name', 'date'
 * @returns {Array}
 */
export function setDistinctObjects(parseObjects, key='id') {
	if (!parseObjects) return [];
	const array = [];
	parseObjects.forEach(parseObj => {
		if (!array.find(item => item[key] === parseObj[key])) {
			array.push(parseObj);
		}
	});
	return array;
}

export const USE_BATCH_SIZE = { batchSize: 1000 };
export const USE_READ_PREFERENCE = { readPreference: 'SECONDARY' };