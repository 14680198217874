import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import CustomThemeProvider from '../components/CustomThemeProvider';
import { getCurrentScreen } from '../components/Responsive';
import Root from './Root';

import { updateScreen } from '../actions/app';

const App = (props) => {

	const dispatch = useDispatch();
	const _updateDisplayScreen = () => {
		dispatch(updateScreen(getCurrentScreen()));
	};

	useEffect(() => {
		window.addEventListener('resize', _updateDisplayScreen);
		_updateDisplayScreen();
		
		return () => {
			window.removeEventListener('resize', _updateDisplayScreen);
		}
	});

	return (
		<HelmetProvider>
			<CustomThemeProvider>
				<Root>{ props.children }</Root>
			</CustomThemeProvider>
		</HelmetProvider>
	);
};

export default App;