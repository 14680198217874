import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText } from '@material-ui/core';

import CustomCard from '../components/CustomCard';
import { getOrdersDispatchSummaryData } from '../actions/orders';

const useStyles = makeStyles((theme) => ({
	root: {
		composes: 'flexColumn center',
	},
	container: {
		composes: 'flexColumn center',
		padding: '0 20px 15px 20px'
	},
  listItemTextRoot: {
		composes: 'flexRow center',
  },
  listItemTextSecondary: {
		marginLeft: 8,
    fontWeight: 600,
    fontSize: 18
  },
  listItemTextInfoSecondary: {
    composes: '$listItemTextSecondary',
    color: theme.color.blue[610],
  },
  listItemTextSuccessSecondary: {
    composes: '$listItemTextSecondary',
    color: theme.palette.success.main,
  },
  listItemTextErrorSecondary: {
    composes: '$listItemTextSecondary',
    color: theme.palette.error.main,
  },
  listItemTextPrimary: {
    fontSize: 18
  }
}));

const DispatchOrdersSummary = ({ user, summary, onCancel }) => {
	const classes = useStyles();

  const items = getOrdersDispatchSummaryData(summary);

  const getItemColor = (item) => {
    if (item.error) return classes.listItemTextErrorSecondary;
    if (item.success) return classes.listItemTextSuccessSecondary;

    return classes.listItemTextInfoSecondary;
  }

	return (
    <CustomCard
      title='Resumé des commandes expédiées'
      cancelAction={onCancel}
      cancelLabel="Fermer"
      content={
        <div className={classes.container}>
          {user &&
            <List className="flexColumn">
              {items.map((item, index) => (
                <ListItem key={item.value + index} role={undefined} dense className="flexRow">
                  <ListItemText
                    primary={item.label}
                    secondary={item.value}
                    classes={{
                      root: classes.listItemTextRoot,
                      secondary: getItemColor(item),
                      primary: classes.listItemTextPrimary
                    }}
                  />
                </ListItem>
              ))}
            </List>
          }
        </div>
      }
    />
	);
};

export default DispatchOrdersSummary;