import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { /*MuiThemeProvider, */withStyles  } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import CustomThemeProvider from './CustomThemeProvider';

class Deferred {
	constructor() {
		this.promise = new Promise((resolve, reject) => {
			this.resolve = resolve;
			this.reject = reject;
		})
	}
}

class PromisifiedDialog extends PureComponent {
	constructor(props) {
		super(props);
		this.state = { open: true };
	}

	cleanup() {
		this.setState({ open: false });

		//---- cleanup after Dialog closing animation ----//
		setTimeout(() => {
			ReactDOM.unmountComponentAtNode(this.props.div)
			document.body.removeChild(this.props.div)
		}, 2000)
	}

	resolve(value) {
		this.props.deferred.resolve(value);
		this.cleanup();
	}

	_ref = contentComponent => {
		this._contentComponent = contentComponent;
		const oldRef = this.props.content.ref;
		oldRef && oldRef(contentComponent);
	}

	render() {
		let { actions, store, classes, contentClasses, ...otherProps } = this.props;
		let index = 0;
		actions = actions.map(action => React.cloneElement(action, {
			key: "action" + (index++),
			action: undefined,
			onClick: () => action.props.action(value => this.resolve(value) , this._contentComponent)
		}));

		// classes contains 'paper' see below

		const insideProvider = (
			<CustomThemeProvider>
				<Dialog
					open={this.state.open}
					classes={classes}
					{...otherProps}
				>
					<DialogContent classes={contentClasses}>
						{React.cloneElement(this.props.content, {
							ref: this._ref
						})}
					</DialogContent>
					<DialogActions>{actions}</DialogActions>
				</Dialog>
			</CustomThemeProvider>
		);

		if (store) {
			const Provider = require("react-redux").Provider;
			return (
				<Provider store={store}>
					{insideProvider}
				</Provider>
			);
		} else {
			return insideProvider;
		}
	}
}

// not withTheme because we need 'innerRef'
PromisifiedDialog = withStyles({paper: {
	overflowY: 'hidden'
	}})(PromisifiedDialog);

/**
 * @param {object} options :
 * 		{Array<ReactElement>} actions each action contains an 'action' event callback with the signature (resolve, contentComponent):void
 * 		{string} title,
 * 		{ReactElement} content
 * @return {Promise}
 */
export function showDialog(options) {
	const deferred = new Deferred();
	const div = document.createElement("div");
	document.body.appendChild(div);

	const { resolveRef, ...passedOptions} = options;

	//---- resolveRef ----//
	const ref = promisifiedDialog => {
		if (resolveRef) {
			resolveRef(value => {
				promisifiedDialog.resolve(value);
			});
		}
	}


	ReactDOM.render(<PromisifiedDialog innerRef={ref} deferred={deferred} div={div} {...passedOptions} />, div)

	return deferred.promise;
}

export const DONT_RESOLVE = "DON'T RESOLVE";

export function showOkCancelDialog({title = "Confirmation", okText = "Ok", cancelText = "Annuler", valueSupplier = () => {}, ...otherOptions}) {
	const actions = [
		<Button
			action={ (resolve, contentComponent) => resolve(null) }
		>{cancelText}</Button>,
		<Button
			color="primary"
			action={
				async (resolve, contentComponent) => {
					const value = await valueSupplier(contentComponent);
					if (value !== DONT_RESOLVE) {
						resolve(value);
					}
				}
			}
		>{okText}</Button>
	];
	return showDialog({
		title,
		actions,
		...otherOptions
	});
}

//---------------------------------------------------------------------------//
//---------------------------- classic functions ----------------------------//
//---------------------------------------------------------------------------//

//------------------------ alert -------------------------//
export function alert({title = "Alert", content, okText = "Ok", ...otherOptions}) {
	return showDialog({
		title,
		actions: [
			<Button
				color="primary"
				action={ (resolve, contentComponent) => resolve() }
			>{okText}</Button>
		],
		content: <div>{content}</div>,
		...otherOptions
	});
}

//------------------------ confirm -------------------------//
export function confirm({title = "Confirmation", message, confirmText = "Confirmer", ...otherOptions}) {
	return showOkCancelDialog({
		title,
		content: <div>{message}</div>,
		valueSupplier: () => true,
		okText: confirmText,
		...otherOptions,
	});
}

//------------------------ prompt -------------------------//
class PromptComponent extends PureComponent {
	componentDidMount() {
		setTimeout(() => {
			this.refs.text.focus();
		}, 200);
	}

	getValue() {
		return this.refs.text.getValue();
	}

	render() {
		return <div>
			<div>{this.props.message}</div>
			<div>
				<TextField
					name="promptValue"
					fullWidth
					defaultValue={this.props.defaultValue}
					ref="text"
				/>
			</div>
		</div>
	}
}

export function prompt({title = "Question", message, defaultValue = "", ...otherOptions}) {
	return showOkCancelDialog({
		title,
		content: <PromptComponent message={message} defaultValue={defaultValue} />,
		valueSupplier: contentComponent => contentComponent.getValue(),
		...otherOptions
	});
}

//---------------------------------------------------------------------------//
//---------------------------- Form and Redux-Form --------------------------//
//---------------------------------------------------------------------------//
/**
 * a Form must have
 * . a submit function
 * . a onSubmit callback prop with the signature (values:Array):void
 * (works with redux-form)
 * @param content "form" is an alias
 * @param title default value : "Form"
 * @param store
 * @param formProps optional
 * @param otherOptions
 * @returns {Promise}
 */
export function showFormDialog({title = "Form", store, content, formProps, ...otherOptions}) {
	content = content || otherOptions.form;

	if (!store && isReduxForm(content)) {
		throw new Error("Missing store when calling showFormDialog with a content of type redux-form");
	}

	let dialogResolve;
	let formComponent;
	let foundWithInnerRef = false;

	//----------------- valueSupplier of the ok Action ---------------------//
	const valueSupplier = async contentComponent => { // contentComponent can be react-redux's Provider
		//---- submit call on the form component ----//
		if (formComponent.submit) {
			// if nothing happens, errors might have been eaten (see the errors using a breakpoint in redux-forms' handleSubmit.js)
			await formComponent.submit();
		} else {
			await formComponent.props.submit();
		}
		return DONT_RESOLVE; // we use the resolve we got from "resolveRef"
	};

	//----------------- formElement cloning ------------------//
	// we don't use the contentComponent provided by the action callback, because it's only the <Provider>
	let formElement = React.cloneElement(content, {
		onSubmit: values => { // can be called on keyboard (like the 'enter' key)
			dialogResolve(values);
		},
		innerRef: formComponentArg => {
			foundWithInnerRef = true;
			formComponent = toForm(formComponentArg);
		},
		ref: formComponentArg => {
			if (!foundWithInnerRef) {
				formComponent = toForm(formComponentArg);
			}
		},
		...formProps
	});

	return showOkCancelDialog({
		resolveRef: resolve => { dialogResolve = resolve; },
		title, valueSupplier, store,
		content: formElement,
		...otherOptions
	});
}

export function isReduxForm(element) {
	return element.type.name === "ReduxForm"
		|| (element.type.WrappedComponent && element.type.WrappedComponent.name === "ReduxForm");
}

/**
 * find the form into wrapped components (works with redux-form)
 * @param component
 * @returns {*}
 */
export function toForm(component) {
	// Note: withRef will be removed from react-redux 6. replaced by forwardRef that automatically returns the wrapped Component's ref.
	if (!component) {
		return null;
	} else if (component.getWrappedInstance) {
		//-- 'connect' was used (hopefully with the {withRef: true} option )--//
		return component.getWrappedInstance();
	} else {
		//-- it's the form --//
		return component;
	}
}
