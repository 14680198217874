import React from 'react';
import PropTypes from 'prop-types';
import { DropzoneDialog } from 'material-ui-dropzone';

import { makeStyles } from '@material-ui/core/styles';
import ImportCsvForm from './ImportCsvForm';

const useStyles = makeStyles({
	root: {
		composes: 'flexCenter'
	},
	form: {
		visibility: 'hidden',
		opacity: 0
	}
});

const ImportDialog = (props) => {
	// props
	const {open, onSave, onClose} = props;

	// styles
	const classes = useStyles(props);

	return (
		<div className={classes.root}>
			<DropzoneDialog
				open={open}
				onSave={onSave}
				acceptedFiles={['.csv']}
				filesLimit={1}
				maxFileSize={5000000}
				onClose={onClose}
				showPreviews={true}
				showFileNamesInPreview={true}
				dialogTitle='Importer un fichier CSV'
				previewText='Fichier sélectionné'
				cancelButtonText='Annuler'
				submitButtonText='Valider'
			/>

			<div className={classes.form}>
				<ImportCsvForm />
			</div>
		</div>
	)
};

ImportDialog.propTypes = {
	open: PropTypes.bool,
	onSave: PropTypes.func,
	onClose: PropTypes.func
};
export default ImportDialog;