import React from 'react';
import PropTypes from 'prop-types';

import CurrentUserIcon from '@material-ui/icons/Person';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';

import ImportIcon from '@material-ui/icons/MoveToInboxOutlined';
import ExpeditionIcon from '@material-ui/icons/LocalShippingOutlined';
import SearchIcon from '@material-ui/icons/NatureOutlined';
import HelpICon from '@material-ui/icons/BookmarkBorderOutlined';

const MenuItemIcon = (props) => {
	const { name } = props;

	let icon = null;
	switch (name) {
		case 'user':
			icon = <CurrentUserIcon />; break;
		case 'scan':
		case 'expedition':
			icon = <ExpeditionIcon />; break;
		case 'search':
			icon = <SearchIcon />; break;
		case 'help':
			icon = <HelpICon />; break;
		case 'import':
			icon = <ImportIcon />; break;
		case 'logout':
			icon = <LogoutIcon />; break;
		default:
			break;
	}

	return icon;
};

MenuItemIcon.propTypes = {
	name: PropTypes.string
};

export default MenuItemIcon;