import React from 'react';
import { useDispatch } from 'react-redux';
import { reduxForm, change } from 'redux-form';

import FormControl from '@material-ui/core/FormControl';

const ImportCsvForm = (props) => {
	// props
	const { handleSubmit } = props;

	// dispatch
	const dispatch = useDispatch();

	const _onChange = (e) => {
		const file = e.target.files[0];
		dispatch(change('importCsvForm', 'csvFile', file));
	};

	return (
		<form onSubmit={handleSubmit}>
			<FormControl margin="normal">
				<input
					name='csvFile'
					type='file'
					onChange={_onChange}
				/>
			</FormControl>
			<input type='submit' />
		</form>
	)
};

export default reduxForm({
	form: 'importCsvForm'
})(ImportCsvForm);