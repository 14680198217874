import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import CustomCard from '../../components/CustomCard';
import OrderTables from './OrdersTable';
import { getOrders } from '../../reducers/orders';

const useStyles = makeStyles({
	root: {
		composes: 'flexColumn stretch',
		minWidth: '40vw',
		maxWidth: '95vw'
	},
	listContainer: {
		minWidth: '50vw',
		maxWidth: '90vw',
	},
	content: {
		maxHeight: '70vh',
		overflow: 'auto'
	}
});


const Orders = (props) => {
	// styles
	const classes = useStyles(props);

	// selectors
	const orders = useSelector(getOrders) || [];

	//------------------------------------//
	//-------------- render --------------//
	//------------------------------------//
	return (
		<div className={classes.root}>
			<CustomCard
				title='Liste des commandes'
				content={<OrderTables rows={orders} />}
				cancelLabel='Fermer'
				rootClassName={classes.listContainer}
				contentClassName={classes.content}
			/>
		</div>
	);
};
export default Orders;