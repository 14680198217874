import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import FormField from '../../components/form/FormField';
import ReduxFormSelect from '../../components/reduxFormMUIWrapper/ReduxFormSelect';

import { activeShippingMethods, SHIPPING_METHOD_TYPES } from '../../actions/orders';

const useStyles = makeStyles(theme => ({
	root: {

	},
	select: {
		minWidth: 300
	},
	checkBoxContainer: {
		composes: 'flexRow center',
		marginTop: 25
	}
}));

const ExpeditionForm = (props) => {
	// state
	const [checked, setChecked] = useState(props.useLastShippingMethod);

	// props
	const { handleSubmit, className } = props;

	// styles
	const classes = useStyles(props);

	// dispatch
	const dispatch = useDispatch();

	const _handleSwitchChange = (event) => {
		const value = event.target.checked
		setChecked(value);
		dispatch(change('expeditionForm', 'useLastShippingMethod', value));
	};

	return (
		<form onSubmit={handleSubmit} className={classNames(classes.root, className)}>
			<FormField
				label='Sélectionner ci-dessous un transporteur'
				name='shippingMethod'
				component={ReduxFormSelect}
				valueClassName={classes.select}
			>
				{Object.keys(SHIPPING_METHOD_TYPES)
					.map(type =>
						<MenuItem
							key={type}
							value={type}
							disabled={!activeShippingMethods.includes(type)}
						>
							{SHIPPING_METHOD_TYPES[type]}
						</MenuItem>
					)
				}
			</FormField>

			<div className={classes.checkBoxContainer}>
				<FormControl component='fieldset'>
					<FormLabel component='legend'>Conservez le même transporteur pour les commandes suivantes</FormLabel>
					<FormControlLabel
						control={<Switch checked={checked} onChange={_handleSwitchChange} name='useLastShippingMethod' />}
						label='Oui'
					/>
				</FormControl>

			</div>

			<input type='submit' />
		</form>
	)
};

export default reduxForm({
	form: 'expeditionForm'
})(ExpeditionForm);