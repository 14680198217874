import React, { Fragment, useState, useEffect } from 'react';
import { usePrevious } from 'react-delta';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import SnackbarContentWrapper from '../components/SnackbarContentWrapper';
import RootMenu from './menu/RootMenu';
import AppHeader from './AppHeader';

import { closeMessage, logout } from '../actions/app';
import {
	getCurrentUser, getDisplayScreen, getLoading,
	getMessage, getMessageVariant, getTitle
} from '../reducers/app';
import PrintIframe from '../components/PrintIframe';

const useStyles = makeStyles(theme => ({
	container: {
		composes: 'flexRow',
		minHeight: '100vh',
		backgroundColor: theme.background.blue[590]
	},
	children: {
		composes: 'flexCenter flex1',
		padding: 12,
		minHeight: '100vh',
	},
	circularProgressColor: {
		color: 'black'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: theme.color.white,
	},
}));

const Root = (props) => {
	// state
	const [menuOpened, setMenuOpened] = useState(false);
	const [backdropOpened, setBackdropOpened] = useState(false);

	// props
	const {	children } = props;

	// styles
	const classes = useStyles(props);

	// dispatch
	const dispatch = useDispatch();

	// selectors
	const title = useSelector(getTitle);
	const message = useSelector(getMessage);
	const variant = useSelector(getMessageVariant);
	const user = useSelector(getCurrentUser);
	const displayScreen = useSelector(getDisplayScreen);
	const loading = useSelector(getLoading);

	//-------------------------------------//
	//------------ backdrop ---------------//
	//-------------------------------------//
	const _handleCloseBackdrop = () => {
		setBackdropOpened(false);
	};

	useEffect(() => {
		setTimeout(() => {
			setBackdropOpened(loading);
		}, 200);

		return () => {
			setBackdropOpened(false);
		}
	}, [loading]);

	//-------------------------------------//
	//------------ root menu --------------//
	//-------------------------------------//
	const _logout = () => dispatch(logout());

	const _handleChangeMenu = () => setMenuOpened(!menuOpened);

	const previousChildren = usePrevious(children);
	useEffect(() => {
		if (displayScreen === 'mobile' && menuOpened) {
			document.body.style.overflow = 'hidden'; // no window scroll when the left menu is shown
		} else {
			document.body.style.overflow = '';
		}

		if (previousChildren !== children && menuOpened) {
			//--------- close the menu --------//
			setMenuOpened(!menuOpened);
		}
	}, [displayScreen, menuOpened, previousChildren, children]);

	const pathName = window.location.pathname;

	return (
		<Fragment>
			<Helmet>
				<title>{ title || 'COOVZ' }</title>
			</Helmet>
			<div
				className='flexColumn stretch'
			>
				<AppHeader user={user} logout={_logout} onClickUserButton={_handleChangeMenu} />
				<div className={classes.container}>
					{ user &&
						<RootMenu
							open={menuOpened}
							pathName={pathName}
						/>
					}
					<div className={classes.children}>
						{ children }
					</div>
				</div>

			</div>

			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				open={message != null}
				autoHideDuration={4000}
				onClose={() => dispatch(closeMessage())}
			>
				<SnackbarContentWrapper
					variant={variant}
					message={message || ""}
				/>
			</Snackbar>

			<Backdrop
				className={classes.backdrop}
				open={backdropOpened}
				onClick={_handleCloseBackdrop}
			>
				<CircularProgress color='inherit' />
			</Backdrop>

			<PrintIframe />
		</Fragment>
	);
};
export default Root;