import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

import MenuItemIcon from './MenuItemIcon';

const useStyles = makeStyles({
	root: {
		composes: 'flexRow center',
		transition: 'all 300ms ease-in-out',
		//overflowX: 'hidden',
		minHeight: 25,
		//width: 60
	},
	selected: {
		backgroundColor: 'rgba(0,0,0,0.08)',
		fontWeight: 'bold'
	},
	menuOpened: {
		width: 220
	},
	span: {
		transition: 'all 300ms ease-in-out',
		marginLeft: 15,
		opacity: 1
	},
	hidden: {
		//opacity: 0
	}
});

const RootMenuItem = (props) => {
	// props
	const { label, name, selected, menuOpened, onClick } = props;

	// styles
	const classes = useStyles();

	const _onClick = () => {
		onClick && onClick(name);
	};

	return (
		<MenuItem
			id={name}
			onClick={_onClick}
			className={
				classNames(
					classes.root,
					{ [classes.selected] : selected },
					{ [classes.menuOpened] : menuOpened }
				)
			}
			title={label}
		>
			<MenuItemIcon name={name} />
			<span
				className={
					classNames(
						classes.span,
						{ [classes.hidden] : !menuOpened }
					)
				}
			>
	      { label }
      </span>
		</MenuItem>
	);
};

RootMenuItem.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	onClick: PropTypes.func,
	isVisible: PropTypes.bool
};
export default RootMenuItem;