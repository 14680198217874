import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button/Button';

import { showHome } from '../actions/app';

const useStyles = makeStyles(theme => ({
	root: {
		minWidth: '45vw',
		maxWidth: '55vw'
	},
	header: {
		padding: '10px 20px',
		borderBottom: theme.border.grey[520],
	},
	content: {

	},
	actions: {
		borderTop: theme.border.grey[620],
		backgroundColor: theme.background.grey[505],
		width: '100%',
		padding: '4px 10px',
		justifyContent: props => props.actions ? 'flex-start' : 'flex-end'
	},
	buttons: {
		display: 'flex',
		alignItems: 'center'
	},
	actionLabel: {
		marginRight: 20
	},
	okBtn: {
		marginLeft: 10
	}
}));

const CustomCard = (props) => {
	// props
	const {
		elevation = 3, rootClassName,
		title, titleClassName,
		content, contentClassName,
		actionLabel,
		withActionButtons = true, actions, actionClassName,
		okAction, okLabel = 'Valider',
		cancelAction, cancelLabel = 'Annuler'
	} = props;

	// styles
	const classes = useStyles(props);

	const actionItems = actions ? actions :
		<div className={classes.buttons}>
			{actionLabel && (
				<div className={classes.actionLabel}>
					{actionLabel}
				</div>
			)}
			<Button
				color='secondary'
				onClick={cancelAction || showHome}
			>
				{cancelLabel}
			</Button>

			{ okAction &&
				<Button
					color='primary'
					onClick={okAction}
					classes={{root: classes.okBtn}}
				>
					{okLabel}
				</Button>
			}
		</div>;

	return (
		<Card
			elevation={elevation}
			classes={{root: classNames(classes.root, rootClassName)}}
		>
			{/*---- header ----*/}
			{ title &&
				<CardHeader
					title={
						<Typography variant='h5'>
							{title}
						</Typography>
					}
					classes={{root: classNames(classes.header, titleClassName)}}
				/>
			}

			{/*---- content ----*/}
			<CardContent
				classes={{root: classNames(classes.content, contentClassName)}}
			>
				{content}
			</CardContent>

			{/*---- actions ----*/}
			{ withActionButtons &&
				<CardActions
					classes={{root: classNames(classes.actions, actionClassName)}}
				>
					{actionItems}
				</CardActions>
			}
		</Card>
	)
};

CustomCard.propTypes = {
	elevation: PropTypes.number,
	rootClassName: PropTypes.any,
	title: PropTypes.string,
	titleClassName: PropTypes.any,
	content: PropTypes.node,
	contentClassName: PropTypes.any,
	withActionButtons: PropTypes.bool,
	actions: PropTypes.node,
	actionClassName: PropTypes.any,
	okAction: PropTypes.func,
	okLabel: PropTypes.string,
	cancelAction: PropTypes.func,
	cancelLabel: PropTypes.string,
};

export default CustomCard;