import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

const ReduxFormTextField = props => {

	const { input: { value , onChange }, meta: { error, touched }, label, multiLine = false, rowsMax, type = 'text', placeholder, fullWidth } = props;
	const text = value || '';

	const _onChange = (event) => {
		onChange(event.target.value);
	};

	return (
		<div className='width100'>
			<TextField
				label={label} 
				value={text}
				onChange={_onChange}
				multiline={multiLine}
				rowsMax={multiLine && rowsMax ? rowsMax : ''}
				type={type}
				placeholder={placeholder}
				fullWidth ={fullWidth}
			/>
			{ touched && error && <FormHelperText error>{ error }</FormHelperText>	}
		</div>
	)
};

export default ReduxFormTextField;