import React from 'react';
import { reduxForm, Field } from 'redux-form';

import { validatePassword } from '../../utils';
import ReduxFormTextField from './../../components/reduxFormMUIWrapper/ReduxFormTextField';

const validate = values => {
	const errors = {};
	if (!values.username) {
		errors.username = 'Required';
	}
	validatePassword(values.password, errors);
	return errors;
};

const LoginForm = (props) => {
	const { handleSubmit } = props;
	return (
		<form onSubmit={handleSubmit}>
			<Field
				name='username' component={ReduxFormTextField}
				label="Email ou pseudo"
			/><br/>
			<Field
				name='password' component={ReduxFormTextField}
				label="Mot de passe"
				type='password'
			/><br/>
			<input id='login' type="submit"/>
		</form>
	)
};

export default reduxForm({
	form: 'login',
	validate
})(LoginForm);