import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import BarcodeReader from 'react-barcode-reader';
import FormField from '../../components/form/FormField';
import { scanOrder } from '../../actions/orders';


const validate = values => {
	const errors = {};
	if (!values.orderId) {
		errors.orderId = 'Numéro de commande obligatoire';
	}
	return errors;
};

const useStyles = makeStyles({
	root: {
		composes: 'flexColumn center'
	}
});

const ScanForm = (props) => {
	const { handleSubmit } = props;

	// styles
	const classes = useStyles(props);

	// dispatch
	const dispatch = useDispatch();

	useEffect(() => {
		setTimeout(() => {
			const orderIdTextField = document.querySelector('input[type="text"]');
			if (orderIdTextField) {
				orderIdTextField.focus();
			}
		}, 200);
	}, []);

	// scan from BarcodeReader
	const _onScan = value => {
		console.log(value);
		if (value) {
			dispatch(scanOrder(value));
		}
	};

	return (
		<form onSubmit={handleSubmit} className={classes.root}>
			<BarcodeReader
				onScan={_onScan}
			/>
			<FormField
				fullWidth
				label='N° commande'
				name='orderId'
			/>

			<input type='submit' />
		</form>
	)
};

export default reduxForm({
	form: 'scanForm',
	validate
})(ScanForm);