import Parse from 'parse';
import axios from 'axios';
import moment from 'moment';

import { getDownloadServerUrl, showScanPage, showMessage, showHome, showSearchPage } from './app';
import { onEnter, showParseObj, actionWithLoader, push, getPublicURL } from './utils';

import { getOrder, getOrders } from '../reducers/orders';
import { RECIPIENT_EMAIL } from '../constants';

const Order = Parse.Object.extend('Order');

export const SHIPPING_METHOD_TYPES = {
	'dhl': 'DHL - COMPTE PHOTOBOX',
	'colisPrive': 'COLIS PRIVE - COMPTE COOVZ',
	'colissimo': 'COLISSIMO - COMPTE INTERNE',
	'delivengoPrio': 'DELIVENGO PRIO - COMPTE INTERNE',
	'delivengoSuivi': 'DELIVENGO SUIVI - COMPTE INTERNE',
	'shipIt': 'SHIP IT',
};

export const EXTENDED_SHIPPING_METHOD_TYPES = {
	...SHIPPING_METHOD_TYPES,
	'poste': 'LA POSTE',
};

export const COUNTRIES = [
	{ value: 'france', label: 'France'},
	{ value: 'europe', label: 'Europe'},
	{ value: 'world', label: 'Reste du monde'},
];

export const PRODUCTS = [
	{ value: 'sticker', label: 'Sticker'},
	{ value: 'glossy-sticker', label: 'Glossy Sticker'},
	{ value: 'poster', label: 'Poster'},
	{ value: 'die-cut-magnet', label: 'Die cut magnet'},
	{ value: 'metal-print', label: 'Metal print'},
	{ value: 'laptop-sleeve', label: 'Laptop sleeve'},
	{ value: 'transparent-sticker', label: 'Transparent sticker'},
];

export const ORDER_TRACKING_FIELD = {
	'dhl': 'trackingIdDHL',
	'colisPrive': 'trackingIdColisPrive',
	'delivengoPrio': 'trackingIdDelivengoPrio',
	'delivengoSuivi': 'trackingIdDelivengoSuivi',
	'colissimo': 'trackingIdColissimo',
	'shipIt': 'trackingIdShipIt',
}

export const activeShippingMethods = ['dhl', 'colisPrive', 'colissimo', 'delivengoPrio', 'delivengoSuivi', 'shipIt'];
export const extendedActiveShippingMethods = [...activeShippingMethods, 'poste'];
export const getCountryLabel = (value) => COUNTRIES.find(country => country.value === value);
export const getProductLabel = (value) => PRODUCTS.find(product => product.value === value);
/**
 * get tracking number for row object from orders
 * @param row
 * @returns {string}
 */
export function getTrackingNumberFromRow(row) {
	if (!row) return '';
	const shippingMethod = row.shippingMethod;

	const trackingId = row.trackingId;
	if (trackingId) {
		return trackingId;
	}
	const trackingIds = row.trackingIds || [];
	const savedTrackingId = trackingIds.find(item => item.shippingMethod === shippingMethod);
	if (savedTrackingId) {
		return savedTrackingId;
	} else if (Object.keys(ORDER_TRACKING_FIELD).includes(shippingMethod)) {
		// it will be removed
		return row[ORDER_TRACKING_FIELD[shippingMethod]] || '';
	}
	return '';
}

/**
 * get trackingId for current order
 * @param order
 * @param [shippingMethod]
 * @returns {string}
 */
export function getOrderTrackingNumber(order, shippingMethod) {
	if (!order) return '';

	const trackingId = order.get('trackingId');
	if (trackingId) {
		return trackingId;
	}
	if (!shippingMethod) {
		shippingMethod = order.get('shippingMethod');
	}
	const trackingIds = order.get('trackingIds') || [];
	const savedTrackingId = trackingIds.find(item => item.shippingMethod === shippingMethod);
	if (savedTrackingId) {
		return savedTrackingId;
	} else if (Object.keys(ORDER_TRACKING_FIELD).includes(shippingMethod)) {
		// it will be removed
		return order.get(ORDER_TRACKING_FIELD[shippingMethod]) || '';
	}
	return '';
}

/**
 * get shippingMethod label
 * @param order
 * @returns {*}
 */
export function getShippingMethodLabel(order) {
	if (!order) return '';
	const shippingMethod = order.get('shippingMethod');
	if (Object.keys(SHIPPING_METHOD_TYPES).includes(shippingMethod)) {
		return SHIPPING_METHOD_TYPES[shippingMethod];
	}
	return 'non défini';
}

/**
 * get shippingMethod key for printing label
 * @param shippingMethod
 * @returns {string}
 */
export function getShippingMethodKey(shippingMethod) {
	switch (shippingMethod) {
		case 'dhl':
			return 'DHL';
		case 'colisPrive':
			return 'COLIS_PRIVE';
		case 'colissimo':
			return 'COLISSIMO';
		case 'delivengoPrio':
			return 'DELIVENGO_PRIO';
		case 'delivengoSuivi':
			return 'DELIVENGO_SUIVI';
		case 'shipIt':
			return 'SHIPIT';
		default:
			return '';
	}
}

/**
 * get clientName
 * @param orderOrClientName
 * @returns {string}
 */
export function getClientNameText(orderOrClientName) {
	if (!orderOrClientName) return '';
	const clientName = orderOrClientName === 'object' ? orderOrClientName.get('clientName') : orderOrClientName;
	switch (clientName) {
		case 'photobox':
			return 'PHOTOBOX';
		case 'redbubble':
			return 'REDBUBBLE';
		case 'streepit':
			return 'STREEPIT';
		default:
			return 'COOVZ';
	}
}

/**
 * upload csv file to server using axios
 * @param file
 * @returns {Function}
 */
export function uploadFile(file) {
	const path = getDownloadServerUrl() + '/importFromCsv';
	const formData = new FormData();

	formData.append('csvFile', file);
	return (dispatch) => {
		try {
			axios.post(path, formData)
			.then(async resolve => {
				dispatch({
					type: 'MESSAGE',
					message: resolve.data,
					variant: 'success',
				});
				const orders = await Parse.Cloud.run('searchOrders', { recentlyImported: true });
				if (orders && Array.isArray(orders)) {
					dispatch({
						type: 'ORDERS_LOADED',
						orders
					});
					showOrders();
				}

			})
			.catch(reject => dispatch({
				type: 'MESSAGE',
				message: reject.data,
				variant: 'error'
			}));
		} catch (e) {
			console.log('error while uploading file : ' + e.message);
		}

	}
}

/**
 * find order
 * @param orderId
 * @param state
 * @param firstName
 * @param lastName
 * @param expeditionDate
 * @returns {Promise<*>}
 */
async function findOrder({orderId, state, firstName, lastName, expeditionDate}) {
	const query = new Parse.Query(Order)
		.notEqualTo('deleted', true);

	if (orderId) {
		//------------------------------------------------------//
		//----------------- query by orderId -------------------//
		//------------------------------------------------------//
		query.equalTo('orderId', orderId);
	} else {
		//------------------------------------------------------//
		//--- by state / firstName, lastName, expeditionDate ---//
		//------------------------------------------------------//
		if (state) {
			query.containedIn('state', state);
		}
		if (firstName) {
			query.equalTo('firstName', firstName);
		}
		if (lastName) {
			query.equalTo('lastName', lastName);
		}
		if (expeditionDate) {
			query.greaterThanOrEqualTo('expeditionDate', expeditionDate);
		}
	}

	return await query.first();
}

/**
 * scan order
 */
export function scanOrder(orderId) {
	return async (dispatch) => {
		const order = await findOrder({orderId});
		if (order) {
			dispatch({
				type: 'ORDER_LOADED',
				order
			});
			return order;
		}
		return null;
	}
}

/**
 * load ordersCounts
 * @returns {Function}
 */
export function loadStatsThunk() {
	return async (dispatch) => {
		const stats = await Parse.Cloud.run('getOrdersStats');
		if (stats && typeof stats === 'object' && Object.keys(stats).length > 0) {
			dispatch({
				type: 'STATS_LOADED',
				stats
			});
		}
	}
}

/**
 * search orders from params
 * @param params it contains { orderId, state, firstName, lastName, expeditionDate }
 * @returns {Function}
 */
export function searchOrders(params) {
	return async (dispatch) => {
		const { orderId, state, firstName, lastName, expeditionDate } = params;
		console.log('expeditionDate: ', expeditionDate);
		if (orderId) {
			const order = await findOrder(params);
			if (order) {
				dispatch({
					type: 'ORDER_LOADED',
					order
				});
				return order;
			}
		}

		const orders = await Parse.Cloud.run('searchOrders', {	state, firstName, lastName, expeditionDate });
		dispatch({
			type: 'ORDERS_LOADED',
			orders
		});
		return orders;
	}
}

const formatDispatchOrdersValues = (formValues) => {
	const values = { ...formValues};
	if (values.products) {
		// maybe the products selection will be multiple later
		if (typeof values.products === 'string') {
			values.products = [values.products];
		}
	}

	return values;
}
/**
 *
 * @param {*} formValues  { state, expeditionDate, product, expeditionDate, country, shippingMethod }
 * @returns
 */
export function dispatchOrders(formValues) {
	return async (dispatch) => {

		const values = formatDispatchOrdersValues(formValues);
		const summary = await Parse.Cloud.run('dispatchOrders', values);

		dispatch({
			type: 'ORDERS_DISPATCH_SUMMARY_LOADED',
			ordersDispatchSummary: {
				ordersTotalTreated: summary.ordersTotalTreated,
				ordersTotalWithError: summary.ordersTotalWithError,
				products: summary.products,
				shippingMethod: summary.shippingMethod,
				country: summary.country,
				date: summary.date,
			}
		});
	}
}

export function getOrdersStateByField(formValues) {
	return async (dispatch) => {
		dispatch({
			type: "LOCAL_LOADING_START"
		});

		const values = formatDispatchOrdersValues(formValues);
		const count = await Parse.Cloud.run('getOrdersStateByField', values);

		dispatch({
			type: 'ORDERS_COUNT_LOADED',
			count
		});
		dispatch({
			type: "LOCAL_LOADING_END"
		});
	}
}

export function clearOrderCount() {
	return async (dispatch) => {
		dispatch({
			type: 'ORDERS_COUNT_CLEARED',
		});
	}
}

export function clearDispatchOrdersSummary() {
	return async (dispatch) => {
		dispatch({
			type: 'ORDERS_DISPATCH_SUMMARY_CLEARED',
		});
	}
}

//--------------------------------------------------------//
//-------------------- loading order ---------------------//
//--------------------------------------------------------//
export function onEnterOrder(store) {
	return onEnter({
		store,
		actionThunk: params => {
			return async (dispatch, getState) => {
				const orderId = params.orderId;
				const order = await loadOrderThunk(orderId)(dispatch, getState);
				if (!order) {
					// order not found
					showHome();
				}
			}
		}
	});
}

export function loadOrderThunk(orderId) {
	return async (dispatch, getState) => {
		const currentOrder = getOrder(getState());
		if (!currentOrder || currentOrder.id !== orderId) {
			// loading order
			const order = await new Parse.Query('Order')
				.equalTo('objectId', orderId)
				.first(orderId);

			dispatch({
				type: 'ORDER_LOADED',
				order
			});
			return order;
		}

		return currentOrder;
	}
}

export function updateCurrentOrderThunk() {
	return async (dispatch, getState) => {
		const currentOrder = getOrder(getState());
		if (currentOrder && currentOrder.id) {
			const order = await new Parse.Query('Order')
				.equalTo('objectId', currentOrder.id)
				.first();

			dispatch({
				type: 'ORDER_LOADED',
				order
			});
		}
	}
}

export function onEnterOrders(store) {
	return onEnter({
		store,
		actionThunk: params => {
			return async (dispatch, getState) => {
				const orders = getOrders(getState());
				if (!orders) {
					showSearchPage();
				}
			}
		}
	});
}

//--------------------------------------------------------//
//----------------- order expedition ---------------------//
//--------------------------------------------------------//
export function onEnterExpeditionPage(store) {
	return onEnter({
		store,
		actionThunk: () => {
			return async (dispatch, getState) => {
				const currentOrder = getOrder(getState());
				if (!currentOrder) {
					showScanPage();
				}
			}
		}
	});
}

/**
 * dispatch order using current shippingMethod
 * @param values
 * @param showOrderDetails
 * @returns {function(*=, *=): Promise<void>}
 */
export function dispatchOrder(values, showOrderDetails = false) {
	return actionWithLoader(async (dispatch, getState) => {
		/**
		 * the following actions:
		 - connection to the carrier selected via the API
		 - sending customer information
		 - Receipt of the label with the tracking number. We receive a pdf from the carrier.
		 - automatic label printing
		 - saving the tracking number into database
		 */
		const state = getState();
		const currentOrder = getOrder(state);

		await showMessage('Traitement en cours...')(dispatch, getState);
		const result = await Parse.Cloud.run('dispatchOrder', {
			id: currentOrder.id,
			shippingMethod: values.shippingMethod
		});

		if (result && typeof result === 'object') {
			const { success, message, order } = result;
			if (success && showOrderDetails) {
				// success
				showOrder(order.id);
			} else if (message) {
				// failure
				await showMessage(message)(dispatch, getState);
			}
		} else {
			// unknown error
			await showMessage("Impossible d'expédier la commande")(dispatch, getState);
		}
	})
}

/**
 * set state as shipping
 * @returns {function(*=, *=): Promise<void>}
 */
export function setStateAsShipping(orderId) {
	return actionWithLoader(async (dispatch, getState) => {
		await Parse.Cloud.run('markOrder', { orderId },{});
		await showMessage('Commande marquée comme expédiée')(dispatch, getState);
	})
}

export function testTask() {
	return actionWithLoader(async (dispatch, getState) => {
		await showMessage('Traitement en cours...')(dispatch, getState);
		await Parse.Cloud.run('testTask');
	})
}

export function purgeTaskQueue() {
	return actionWithLoader(async (dispatch, getState) => {
		await showMessage('Purge de la file d\'attente en cours...')(dispatch, getState);
		await Parse.Cloud.run('purgeTaskQueue');
	})
}
//--------------------------------------------------------//
//-------------------- function utils --------------------//
//--------------------------------------------------------//
/**
 * get client fullName of current order
 * @param order
 * @returns {string}
 */
export function getClientFullName(order) {
	const firstName = order.get('firstName') || '';
	const lastName = order.get('lastName') || '';
	const names = [firstName, lastName];
	return names.join(' ');
}

export const getDispatchOrdersCountLabel = (count) => {
	if (count <= 1) {
		return `${count} commande concernée`;
	}
	return `${count} commandes concernées`;
}

/**
 * get client address
 * @param order
 * @returns {string}
 */
export function getClientAddress(order) {
	const addressStr = [order.get('address')];

	const additionalAddress = order.get('additionalAddress');
	if (additionalAddress) {
		addressStr.push(additionalAddress);
	}
	addressStr.push(order.get('zipCode'));
	addressStr.push(order.get('city'));
	return addressStr.join(', ');
}

/**
 * format orders dispatch summary
 * @param {*} summary { shippingMethod, product (Shipping Errors / Posters, Sticker / 29-06-2023), country, ordersTotalTreated, ordersTotalWithError }
 * @returns
 */
export const getOrdersDispatchSummaryData = (summary) => {
	let product = '';

	if (summary.products) {
		const productLabels = summary.products.map((product) => getProductLabel(product)?.label);
		const productLabel = productLabels.length ? productLabels.join(', ') : productLabels[0];
		product += productLabel + ' - ';
	}

	const selectedCountry = getCountryLabel(summary.country);
	if (selectedCountry) {
		product += selectedCountry.label + ' - ' ;
	}
	if (summary.shippingMethod) {
		product += EXTENDED_SHIPPING_METHOD_TYPES[summary.shippingMethod];
	}

	const data = [
		{
			label: 'Nombre de commandes traitées: ',
			value: summary.ordersTotalTreated,
			success: true
		},
		{
			label: 'Produit: ',
			value: product // POSTERS - Europe - Colissimo
		},
	];

	if (summary.date) {
		data.push({
			label: 'Date: ',
			value: moment(summary.date).format('DD-MM-YYYY')
		});
	}

	// display error stats
	if (summary.ordersTotalWithError) {
		data.push({
			label: 'Nombre de commandes en erreur: ',
			value: summary.ordersTotalWithError || 0,
			error: true
		});
		data.push({
			label: 'Fichier envoyé à',
			value: RECIPIENT_EMAIL
		});
	}

	return data;
}

/**
 * default date (dueDate) for filter form
 * default value: one day earlier
 * @returns 
 */
export const getDispatchOrdersDefaultDate = () => moment().subtract(1, 'day').format('YYYY-MM-DD');

export const getDispatchOrdersInitialValues = () => ({
	date: getDispatchOrdersDefaultDate(),
	country: COUNTRIES[0].value // 'france'
});

//--------------------------------------------------------//
//---------------- LAST_SHIPPING_METHOD ------------------//
//--------------------------------------------------------//
const localShippingMethodPath = 'LAST_SHIPPING_METHOD';
export function saveLastShippingMethod(shippingMethod) {
	localStorage.setItem(localShippingMethodPath, shippingMethod);
}
export function getLastShippingMethod() {
	return localStorage.getItem(localShippingMethodPath);
}
export function clearLastShippingMethod() {
	localStorage.removeItem(localShippingMethodPath);
}

//--------------------------------------------------------//
//---------------------- Routing -------------------------//
//--------------------------------------------------------//
export function showOrder(orderOrId, fromNewTab= false) {
	if (fromNewTab) {
		const url = getPublicURL() + '/order-' + orderOrId
		window.open(url);
		return;
	}
	return showParseObj('order', orderOrId);
}
export function showOrders() {
	return push('/orders');
}
