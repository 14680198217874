import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import WaitingIcon from '@material-ui/icons/HourglassEmptyRounded';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';

import { getClientNameText } from '../actions/orders';

const useStyles = makeStyles(theme => ({
	root: {
		composes: 'flexColumn stretch'
	},
	row: {
		composes: 'flexRow center nowrap spaceBetween',
		minWidth: '45vw',
		maxWidth: '55vw',
		marginTop: 15
	},
	container: {
		fontSize: props => props.direction === 'row' ? 18 : 22,
		color: theme.color.blue[610],
		marginTop: 25,
	},
	titleContent: {
		composes: 'flexRow center',
		fontWeight: props => props.bold ? 'bold' : 'normal'
	},
	title: {
		fontSize: props => props.direction === 'row' ? 16 : 20,
		color: theme.color.black,
		textTransform: 'uppercase',
		marginLeft: 15
	},
	stats: {
		marginTop: 12,
		paddingLeft: 40,
		color: props => props.whiteText ? 'white' : 'inherit'
	},
	icon: {
		marginLeft: 6,
		marginRight: 10
	}
}));

const ACTIVE_CLIENT_NAME = [/*'photobox', */'redbubble'];

const Summary = (props) => {
	// props
	const { className, ordersCount, direction = 'column' } = props;

	// styles
	const classes = useStyles(props);

	if (!ordersCount) return null;

	const StatsByClientName = ({title, waitingCount, totalCount}) => {
		return (
			<div className={classes.container}>
				<div className={classes.titleContent}>
					<div className={classes.title}>{title}</div>
				</div>
				<div className={classes.stats}>
					<div className='flexRow center'>
						<span className={classes.icon}><WaitingIcon color='action' /></span>
						<span>{waitingCount} Commande(s) en attente</span>
					</div>
					<div className='flexRow center'>
						<span className={classes.icon}><DateRangeRoundedIcon color='action' /></span>
						<span>{totalCount} Total des commandes </span>
					</div>

				</div>
			</div>
		);
	}

	return (
		<div
			className={classNames(
				className, {
					[classes.root]: direction === 'column',
					[classes.row]: direction === 'row'
				})
			}
		>
			{
				ACTIVE_CLIENT_NAME.map((clientName, index) =>
					<StatsByClientName
						key={index}
						title={getClientNameText(clientName)}
						waitingCount={ordersCount[clientName].waiting}
						totalCount={ordersCount[clientName].total}
					/>
				)
			}

		</div>
	);
};

Summary.propTypes = {
	user: PropTypes.object,
	ordersCount: PropTypes.object,
	className: PropTypes.any,
	withTotal: PropTypes.bool,
	withLabelStats: PropTypes.bool
};
export default Summary;
