import React, { useState } from 'react';
import { change, reset, submit } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';

import CustomCard from '../components/CustomCard';
import { confirm } from '../components/react-await-dialog';
import ImportDialog from './import/ImportDialog';
import Summary from './Summary';

import {testTask, uploadFile, purgeTaskQueue, dispatchOrders, clearDispatchOrdersSummary, getDispatchOrdersInitialValues, getDispatchOrdersCountLabel } from '../actions/orders';
import { showLogin } from '../actions/app';
import { getCurrentUser, getLocalLoading } from '../reducers/app';
import { getOrdersCountSelector, getOrdersDispatchSummarySelector, getStats } from '../reducers/orders';
import { setLoading } from '../utils';
import DispatchOrdersForm from './orders/DispatchOrdersForm';
import DispatchOrdersSummary from './DispatchOrdersSummary';

const useStyles = makeStyles(theme => ({
	root: {
		composes: 'flexColumn center',
	},
	container: {
		composes: 'flexColumn center',
		padding: '0 20px 15px 20px'
	},
	dispatchOrdersCard: {
		marginBottom: 40,
		minWidth: '70vw',
		[theme.breakpoints.down('lg')]: {
			minWidth: '65vw',
		},
		[theme.breakpoints.down('md')]: {
			minWidth: '75vw',
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: '98vw',
		}
	},
	btn: {
		margin: theme.spacing(1),
		marginTop: 45
	},
	summary: {
		composes: 'flex1'
	}
}));

const Home = (props) => {
	// state
	const [openDialog, setOpenDialog] = useState(false);
	const [importInProgress, setImportInProgress] = useState(false);
	const ordersDispatchSummary = useSelector(getOrdersDispatchSummarySelector);
	const count = useSelector(getOrdersCountSelector);
	const loading = useSelector(getLocalLoading);
	// styles
	const classes = useStyles(props);
	

	// dispatch
	const dispatch = useDispatch();

	// selectors
	const user = useSelector(getCurrentUser);
	const stats = useSelector(getStats);

	const _importCSV = () => {
		setOpenDialog(true);
	};

	const _closeDialogForm = () => {
		setOpenDialog(false);
	};

	const _uploadFile = async(csvFile) => {
		await dispatch(uploadFile(csvFile));
		_closeDialogForm();
	};

	const _handleSave = (files) => {
		if (files && files.length) {
			setImportInProgress(true);
			const csvFile = files[0];
			dispatch(change('importCsvForm', 'csvFile', csvFile));
			setTimeout(async() => {
				await _uploadFile(csvFile);
				setImportInProgress(false);
			}, 100);
		}
	};

	const _testTask = () => {
		dispatch(testTask());
	}

	const _askConfirmPurge = async () => {
		const confirmed = await confirm({
			message: "Etes-vous sûr de vouloir purger la file d'attente des tâches GC?",
			title: "Confirmation",
		});
		
		if (confirmed) {
			dispatch(purgeTaskQueue())
		}
	}

	// ---------------------------------------------- //
	// ------------- dispatchOrdersForm ------------- //
	// ---------------------------------------------- //
	const _onConfirmOrdersDispatch = async(values) => {
		dispatch(setLoading('start'));
		await dispatch(dispatchOrders(values));
		dispatch(setLoading('end'));
		_onResetDispatchOrdersForm();
	};
	
	const _onDispatchOrders = () => dispatch(submit('dispatchOrdersForm'));
	const _onResetDispatchOrdersForm = () => dispatch(reset('dispatchOrdersForm'));

	const clearDispatchOrdersForm = () => {
		_onResetDispatchOrdersForm();
		handleCloseOrdersDispatchSummary();
	};

	// display default card
	const handleCloseOrdersDispatchSummary = () => dispatch(clearDispatchOrdersSummary());

	return (
		<div className={classes.root}>
			<CustomCard
				rootClassName={classes.dispatchOrdersCard}
				title="Commandes à expédier"
				content={
					<DispatchOrdersForm
						onSubmit={_onConfirmOrdersDispatch}
						initialValues={getDispatchOrdersInitialValues()}
						onCancel={handleCloseOrdersDispatchSummary}
					/>
				}
				okLabel="go"
				okAction={_onDispatchOrders}
				cancelAction={clearDispatchOrdersForm}
				actionLabel={loading ? <CircularProgress size={20} /> : getDispatchOrdersCountLabel(count)}
			/>
			{(ordersDispatchSummary)
				? <DispatchOrdersSummary user={user} summary={ordersDispatchSummary} onCancel={handleCloseOrdersDispatchSummary} />
				: (
						<CustomCard
						title='Résumé du jour'
						content={
							<div className={classes.container}>
								<Summary
									user={user}
									className={classes.summary}
									ordersCount={stats}
									withTotal
									withLabelStats
								/>
		
								{ !user &&
									<Button
										onClick={() => showLogin()}
										variant='contained'
										color='default'
										className={classes.btn}
									>
										Login
									</Button>
								}
		
								{ user &&
									<Button
										onClick={_importCSV}
										variant='contained'
										color='secondary'
										className={classes.btn}
										startIcon={importInProgress ? <CircularProgress /> : <SaveIcon />}
									>
										Importer fichier CSV
									</Button>
								}
		
								{ window.LOCAL &&
									<Button
										onClick={_testTask}
										variant='contained'
										color='primary'
										className={classes.btn}
									>
										Créer une tâche GC Task
									</Button>
								}
		
								{ user &&
									<Button
										onClick={_askConfirmPurge}
										variant='contained'
										color='secondary'
										className={classes.btn}
									>
										Purger la file de tâche GC
									</Button>
								}
							</div>
						}
						withActionButtons={false}
					/>
				)
			}

			<ImportDialog
				open={openDialog}
				onSave={_handleSave}
				onClose={_closeDialogForm}
			/>
		</div>
	);
};

export default Home;