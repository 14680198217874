import React from 'react';
import uid from 'uid';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import classNames from 'classnames';

const ReduxFormSelect = props => {
	const {
		input: { value, onChange },
		meta: { error, touched },
		children, multiple, className, selectRootClassName,
		readOnly = false, renderValue
	} = props;
	const selected = value || [];

	const _onChange = event => {
		onChange(event.target.value);
	};

	return (
		<div className={classNames('width100', selectRootClassName)}>
			<Select
				value={selected} onChange={_onChange}
				input={<Input id={'select_' + uid(4)} readOnly={readOnly} />}
				children={children}
				multiple={multiple}
				classes={{ select: className }}
				renderValue={renderValue}
			/>
			{touched && (error && <FormHelperText error>{error}</FormHelperText>)}
		</div>
	);
};

export default ReduxFormSelect;