import { getData } from './index';

export default function users(
	state = {
		user: null,
		userLocalVersion: 0,
		users: null,
		usersLocalVersion: 0
	}, action) {
	switch (action.type) {
		case 'USER_LOADED':
			return {
				...state,
				user: action.user
			};
		case 'USER_UPDATED':
			return {
				...state,
				user: action.user,
				userLocalVersion: state.userLocalVersion + 1
			};
		default:
			return state
	}
}

//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getUser(state, errorIfNotFound = false) {
	return getData(state, "users.user", errorIfNotFound && "No user data found");
}
export function getUserLocalVersion(state, errorIfNotFound = false) {
	return getData(state, "users.userLocalVersion", errorIfNotFound && "No user local version found");
}