import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submit, reset} from 'redux-form';

import { makeStyles } from '@material-ui/core/styles';

import { setLoading } from '../../utils';
import CustomCard from '../../components/CustomCard';
import Summary from '../Summary';
import SearchForm from './SearchForm';

import { searchOrders, showOrder, showOrders } from '../../actions/orders';
import { getStats } from '../../reducers/orders';

const useStyles = makeStyles({
	root: {
		composes: 'flexColumn center'
	},
	container: {
		composes: 'flexCenter',
		width: '100%',
		minWidth: '40vw',
		maxWidth: '55vw',
		paddingBottom: 30
	},
	title: {
		color: '#CCE0E0',
		marginBottom: 10
	},
	card: {
		width: '100%'
	},
	actions: {
		justifyContent: 'flex-end'
	}
});

const SearchPage = (props) => {
	// state
	const [resetForm, setResetForm] = useState(false);

	// styles
	const classes = useStyles(props);

	// dispatch
	const dispatch = useDispatch();

	// selectors
	const stats = useSelector(getStats);

	const _confirm = async(values) => {
		dispatch(setLoading('start'));
		const results = await dispatch(searchOrders(values));
		if (results && Array.isArray(results)) {
			showOrders();
		} else if (results && typeof results === 'object') {
			showOrder(results.id);
		}
		dispatch(setLoading('end'));
	};

	const _submit = () => dispatch(submit('searchForm'));
	const _cancel = () => {
		dispatch(reset('searchForm'));
		setResetForm(true);
	}

	return (
		<div className={classes.root}>
			<CustomCard
				title='Rechercher une commande'
				content={
					<div className={classes.container}>
						<SearchForm
							onSubmit={_confirm}
							resetForm={resetForm}
						/>
					</div>
				}
				okLabel='Rechercher'
				okAction={_submit}
				cancelAction={_cancel}
			/>
			<Summary ordersCount={stats} direction='row' whiteText bold />
		</div>
	)
};
export default SearchPage;