import React from 'react';
import { Router, Route, IndexRoute } from 'react-router';
import { useStore } from 'react-redux';

import App from './containers/App';
import Home from './containers/Home';

import Login from './containers/login/Login';
import Order from './containers/orders/Order';
import ScanPage from './containers/orders/ScanPage';
import ExpeditionPage from './containers/orders/ExpeditionPage';
import SearchPage from './containers/orders/SearchPage';
import HelpPage from './containers/help/HelpPage';
import Orders from './containers/orders/Orders';

import { onEnterApp, onEnterHome, onEnterUnknownRoute } from './actions/app';
import { onEnterOrder, onEnterExpeditionPage, onEnterOrders } from './actions/orders';

const Routes = (props) => {
	const store = useStore();
	const { history } = props;

	return (
		<Router history={history}>
			<Route path='/' component={App} onEnter={onEnterApp(store)} >
				<IndexRoute component={Home} onEnter={onEnterHome(store)} />
				<Route path='login' component={Login} />

				<Route path='scan' component={ScanPage} />
				<Route path='expedition' component={ExpeditionPage} onEnter={onEnterExpeditionPage(store)} />
				<Route path='search' component={SearchPage} />
				<Route path='help' component={HelpPage} />
				<Route path="order-:orderId" component={Order} onEnter={onEnterOrder(store)} />
				<Route path="orders" component={Orders} onEnter={onEnterOrders(store)} />

				<Route path='*' onEnter={onEnterUnknownRoute(store)} />
			</Route>
		</Router>
	);
};

export default Routes;