import React, { useState } from 'react';
import { submit, reset } from 'redux-form';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import { setLoading } from '../../utils';
import CustomCard from '../../components/CustomCard';
import ScanForm from './ScanForm';

import { showExpeditionPage } from '../../actions/app';
import { scanOrder, showOrder } from '../../actions/orders';

const useStyles = makeStyles(theme => ({
	root: {
		composes: 'flexColumn center stretch',
		background: theme.background.white,
		minWidth: '45vw',
		maxWidth: '80vw',
		minHeight: 200
	},
	header: {
		composes: 'flexRow center',
		width: '100%',
		borderBottom: theme.border.grey[520],
		height: 40,
		padding: '0 20px'
	},
	form: {
		composes: 'flex1',
		paddingTop: 20
	},
	feedback: {
		marginBottom: 30,
		color: 'red'
	},
}));

const ScanPage = (props) => {
	// state
	const [feedback, setFeedback] = useState(null);

	// styles
	const classes = useStyles(props);

	// dispatch
	const dispatch = useDispatch();

	const _scanOrder = async(values) => {
		dispatch(setLoading());
		const order = await dispatch(scanOrder(values.orderId));
		dispatch(setLoading('end'));
		if (order) {
			//------------------------//
			//----- order exists -----//
			//------------------------//
			if (order.get('state') === 'waiting') {
				// go to ExpeditionPage
				showExpeditionPage();
			} else {
				// show order details page
				showOrder(order.id);
			}

		} else {
			//------------------------//
			//------ not exist -------//
			//------------------------//
			setFeedback('Commande non trouvée');
		}
	};
	const _submitOrder = () => dispatch(submit('scanForm'));

	const _reset = () => {
		setFeedback(null);
		dispatch(reset('scanForm'));
	};

	return (
		<CustomCard
			title="Veuillez scanner la commande à expédier"
			content={
				<div className={classes.root}>
					<div className={classes.form}>
						<ScanForm onSubmit={_scanOrder} />
					</div>

					<div className={classes.feedback}>{feedback}</div>
				</div>
			}
			okLabel={feedback ? 'Réinitialiser' : 'Vérifier'}
			okAction={feedback ? _reset : _submitOrder}
			cancelLabel='Fermer'
		/>
	)
};

export default ScanPage;