import React from 'react';
import Clock from 'react-live-clock';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		margin: '0 10px',
		color: theme.color.white
	}
}));

const DigitalClock = (props) => {
	// styles
	const classes = useStyles(props);

	return (
		<div className={classes.root}>
			<Clock format={'HH:mm:ss'} ticking={true} />
		</div>
	)
};
export default DigitalClock;