import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import WaitingIcon from '@material-ui/icons/HourglassEmpty';
import WaitingShipmentIcon from '@material-ui/icons/AssignmentLate';
import ShippedIcon from '@material-ui/icons/AssignmentTurnedIn';
import ShippingMethodIcon from '@material-ui/icons/AirplanemodeActive';
import TrackIcon from '@material-ui/icons/TrackChanges';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { toFrDateTimeString } from '../../utils';
import CustomCard from '../../components/CustomCard';
import Summary from '../Summary';

import { downloadFile, showExpeditionPage, showScanPage } from '../../actions/app';
import { getClientFullName, getClientNameText, getShippingMethodLabel, getOrderTrackingNumber } from '../../actions/orders';
import { getOrder, getStats } from '../../reducers/orders';
import { getCurrentUser } from '../../reducers/app';

const useStyles = makeStyles({
	root: {
		composes: 'flexColumn stretch',
		minWidth: '40vw',
		maxWidth: '80vw'
	},
	orderContainer: {
		minWidth: 450,
		paddingBottom: 20
	},
	content: {
		padding: 5,
		marginTop: 15,
		lineHeight: '24px'
	},
	titleContainer: {
		composes: 'flexRow center spaceBetween',
		marginBottom: 8
	},
	title: {
		//fontSize: 18,
		fontWeight: 'bold',
	},
	chipLabel: {
		color: 'white'
	},
	chipIcon: {
		color: 'white !important'
	},
	infoItem: {
		composes: 'flexRow center',
		marginBottom: 6
	},
	itemIcon: {
		composes: 'flexCenter'
	},
	itemContent: {
		marginLeft: 10
	},
	clientName: {

	},
	shippingMethod: {
		fontWeight: 500,
		color: 'darkGoldenRod'
	},
	error: {
		color: 'red'
	},
	errorDetails: {
		color: 'darkGrey'
	}
});

const Order = (props) => {
	// styles
	const classes = useStyles(props);

	// selectors
	const currentUser = useSelector(getCurrentUser);
	const order = useSelector(getOrder);
	const stats = useSelector(getStats);

	if (!order) {
		return null;
	}

	/*
	const _trackOrder = () => {

	};
	*/

	/**
	 * go to expedition page
	 */
	const _goToExpeditionPage = () => {
		showExpeditionPage();
	};

	/**
	 * print label
	 */
	const _printLabel = async() => {
		const data = { orderId: order.id };
		// open the pdf in the iframe
		await downloadFile({ fileName: 'exportOrderLabel', data, currentUser, windowLocation: 'print' });
		showScanPage();
	};

	const orderState = order.get('state');
	const isWaiting = orderState === 'waiting';
	const isShipping = ['shipping', 'shipped'].includes(orderState);
	const labelDownloadError = order.get('labelDownloadError');
	const errorDetails = order.get('errorDetails');
	const errorDetailsStr = errorDetails && typeof errorDetails === 'object' ? JSON.stringify(errorDetails) : '';
	const expeditionDate = order.get('expeditionDate');
	const expeditionDateStr = expeditionDate && !labelDownloadError ?
		`Expédiée le ${toFrDateTimeString(expeditionDate)}`
		: 'Pas encore expédiée';

	//------------------------------------//
	//----------- order content ----------//
	//------------------------------------//
	const InfoItem = ({icon, content}) => (
		<div className={classes.infoItem}>
			<div className={classes.itemIcon}>{icon}</div>
			<div className={classes.itemContent}>{content}</div>
		</div>
	);
	
	const orderContent =
		<div className={classes.orderContainer}>
			<div className={classes.content}>

				<div className={classes.titleContainer}>
					<Typography variant='h5' className={classes.title}>COMMANDE {order.get('orderId')}</Typography>

					<Tooltip
						disableFocusListener
						title={isShipping ? "Commande expédiée" : "Commande en attente d'expédition"}
					>
						<Chip
							clickable
							label={getClientNameText(order)}
							color={isShipping ? 'primary' : 'secondary'}
							deleteIcon={isShipping ? <DoneIcon /> : <WaitingIcon />}
							onDelete={() => {}}
							classes={{label: classes.chipLabel, deleteIcon: classes.chipIcon}}
						/>
					</Tooltip>
				</div>

				<Typography variant='h6' color='primary' gutterBottom>{getClientFullName(order)}</Typography>
				<Typography variant='subtitle2' gutterBottom>{order.get('address')}</Typography>
				<Typography variant='subtitle2' gutterBottom>{order.get('zipCode') + ' - ' + order.get('city')}</Typography>
				<Typography variant='subtitle2' gutterBottom>{order.get('phone')}</Typography>
			</div>

			<div className={classes.content}>
				<InfoItem
					icon={(isWaiting || labelDownloadError) ? <WaitingShipmentIcon color='secondary' /> : <ShippedIcon color='primary' />}
					content={<div>{expeditionDateStr}</div>}
				/>
				<InfoItem
					icon={<ShippingMethodIcon />}
					content={<div>Mode d'expédition : <span className={classes.shippingMethod}>{getShippingMethodLabel(order)}</span></div>}
				/>
				<InfoItem
					icon={<TrackIcon />}
					content={<div>N° de tracking : {getOrderTrackingNumber(order)}</div>}
				/>
				{
					labelDownloadError && (
						<InfoItem
							icon={<NewReleasesIcon color='secondary' />}
							content={
								<div className='flexColumn'>
									<div><span className={classes.error}>Erreur :</span> {labelDownloadError}</div>
									<div className={classes.errorDetails}>{errorDetailsStr}</div>
								</div>
							}
						/>
					)
				}
			</div>

			{
				isShipping &&
				<Button onClick={_printLabel}>
					Imprimer l'étiquette
				</Button>
			}
		</div>;

	//------------------------------------//
	//-------------- render --------------//
	//------------------------------------//
	return (
		<div className={classes.root}>
			<CustomCard
				title='Détails de la commande'
				content={orderContent}
				okLabel={isShipping ? 'Réexpédier la commande' : 'Expédier la commande'}
				okAction={/* isShipping ? _trackOrder : */ _goToExpeditionPage}
				cancelLabel='Fermer'
			/>
			<Summary ordersCount={stats} direction='row' whiteText bold />
		</div>
	);
};
export default Order;