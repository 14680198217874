import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
	success: {
		backgroundColor: '#1FA086',
	},
	error: {
		backgroundColor: '#fd6664',
	},
	info: {
		backgroundColor: '#00bcd4',
	},
	warning: {
		backgroundColor: '#d17d1d',
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: 20,
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	}
}));

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
};

const SnackbarContentWrapper = (props) => {
	// props
	const { className, onClose, variant, message, ...other } = props;

	// styles
	const classes = useStyles(props);

	const Icon = variantIcon[variant];
	return (
		<SnackbarContent
			className={classNames(classes[variant], className)}
			aria-describedby='client-snackbar'
			message={
				<span id='client-snackbar' className={classes.message}>
					<Icon className={classNames(classes.icon, classes.iconVariant)} />
					{message}
				</span>
			}
			action={onClose ? [
				<IconButton key='close' aria-label='Close' color='inherit' onClick={onClose}>
					<CloseIcon className={classes.icon} />
				</IconButton>,
			] : undefined}
			{...other}
		/>
	);
};

export default SnackbarContentWrapper;