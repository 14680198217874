import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';

import { mobile, MOBILE_HEADER_HEIGHT } from '../../components/Responsive';
import RootMenuItem from './RootMenuItem';

import { getCurrentUser } from '../../reducers/app';
import { logout, showHelpPage, showHome, showScanPage, showSearchPage } from '../../actions/app';

export const APP_MENU_WIDTH = 220;

const useStyles = makeStyles({
	root: {
		composes: 'flexColumn stretch',
		backgroundColor: "#f0f0f0",
		width: APP_MENU_WIDTH,
		position: 'absolute',
		zIndex: 2,
		right: 1,
		transition: 'width 300ms ease-in-out',
		overflowX: 'hidden',
		overflowY: 'auto',
		'& > div': {
			width: APP_MENU_WIDTH
		},
		[mobile]: {
			position: 'fixed',
			top: MOBILE_HEADER_HEIGHT,
			height: '100%',
			paddingTop: 0,
			paddingBottom: 60
		}
	},
	iconText: {
		minHeight: 20,
		[mobile]: {
			padding: '25px 12px'
		}
	},
	hidden: {
		transition: 'width 300ms ease-in-out',
		overflow: 'hidden',
		width: '0px !important'
	},
	logoutBtn: {
		composes: 'flexColumn stretch',
		'& button': {
			height: 50,
			padding: 0,
			justifyContent: 'flex-start',
			paddingLeft: 13
		}
	},
	noDivider: {
		display: 'none'
	}
});

const RootMenu = (props) => {
	// state
	const [selectedMenu, setSelectedMenu] = useState(null);

	// props
	const { open, pathName } = props;

	// styles
	const classes = useStyles();

	// dispatch
	const dispatch = useDispatch();

	// selector
	const user = useSelector(getCurrentUser);

	if (!open) return null;

	const _showMenu = menuName => {
		setSelectedMenu(menuName);
		
		switch (menuName) {
			case 'import':
				showHome();
				break;
			case 'search':
				showSearchPage();
				break;
			case 'scan':
				showScanPage();
				break;
			case 'help':
				showHelpPage();
				break;
			default:
				break;
		}
	};

	const MenuItem = ({name, label, selectedPathName}) => {
		return (
			<RootMenuItem
				name={name}
				label={label}
				onClick={_showMenu}
				selected={selectedMenu === name || pathName === selectedPathName}
			/>
		)
	};
	
	const _logout = () => {
		dispatch(logout());
	};
	
	return (
		<Card classes={{root: classes.root}} elevation={4}>
			<MenuItem
				name="user"
				label={user.get('username')}
			/>

			<Divider />

			<MenuItem
				name='import'
				label='Importer'
				selectedPathName='/import'
			/>

			<MenuItem
				name='search'
				label='Rechercher'
				selectedPathName='/search'
			/>

			<MenuItem
				name='scan'
				label='Expédier'
				selectedPathName='/scan'
			/>

			<MenuItem
				name='help'
				label='Aide'
				selectedPathName='/help'
			/>

			<Divider />

			{/*---- Logout ----*/}
			<RootMenuItem
				name="logout"
				label="Déconnexion"
				onClick={_logout}
			/>
		</Card>
	);
};

export default RootMenu;