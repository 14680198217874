import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';
import { countries } from 'countries-list';
import { makeStyles } from '@material-ui/core/styles';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import HouseIcon from '@material-ui/icons/House';
import Button from '@material-ui/core/Button';

import CustomCard from '../../components/CustomCard';
import ExpeditionForm from './ExpeditionForm';

import {
	clearLastShippingMethod,
	dispatchOrder,
	getClientAddress,
	getClientFullName, getLastShippingMethod,
	getShippingMethodKey,
	saveLastShippingMethod, setStateAsShipping
} from '../../actions/orders';
import { downloadFile, showHome, showScanPage } from '../../actions/app';
import { getOrder } from '../../reducers/orders';
import { getCurrentUser } from '../../reducers/app';


const useStyles = makeStyles(theme => ({
	root: {
		composes: 'flexColumn center stretch',
		background: theme.background.white,
		minWidth: '40vw',
		maxWidth: '65vw',
		minHeight: 200,
		padding: '10px 20px'
	},
	title: {
		maxHeight: 50,
		fontSize: 28,
		borderBottom: theme.border.grey[520],
	},
	orderDetails: {
		width: '100%',
		maxWidth: 460,
		fontSize: 15,
		marginTop: 10
	},
	fieldIcon: {
		composes: 'flexCenter',
		marginRight: 6
	},
	fieldItem: {
		composes: 'flexRow center',
		marginTop: 5
	},
	fieldLabel: {
		minWidth: 100
	},
	fieldValue: {
		composes: 'flex1',
		marginLeft: 10
	},
	form: {
		composes: 'flex1',
		marginTop: 10
	},
	buttons: {
		justifyContent: 'flex-end',
		borderTop: theme.border.grey[620],
		backgroundColor: theme.background.grey[505],
		padding: '0 20px'
	},
	iframe: {
		width: '100%',
		height: 1,
		border: 'none',
		padding: 0,
		margin: 0,
		visibility: 'hidden'
	},
	actionButtons: {
		composes: 'flexRow center stretch',
		width: '100%'
	},
	usualButtons: {
	composes: 'flexRow center flex1',
		justifyContent: 'flex-end'
}
}));

const ExpeditionPage = (props) => {
	// state
	const [printInProgress, setPrintInProgress] = useState(false);
	const [countryInfo, setCountryInfo] = useState(null);

	// styles
	const classes = useStyles(props);

	// dispatch
	const dispatch = useDispatch();

	// selectors
	const currentUser = useSelector(getCurrentUser);
	const order = useSelector(getOrder);

	useEffect(() => {
		if (order && order.has('countryCode')) {
			const country = countries[order.get('countryCode')];
			if (country) {
				setCountryInfo(country);
			}
		}

		return () => {
			setCountryInfo(null);
		}
	}, [order]);

	const orderId = order?.get('orderId') || '';

	const lastShippingMethod = useMemo(() => {
		const shippingMethodLocalStorage = getLastShippingMethod();
		if (!shippingMethodLocalStorage && countryInfo) {
			// we preselect according to the rule:
			//	. 'dhl' for shipments to Europe
			//	. 'colisPrive' for others
			return  countryInfo.continent === 'EU' ? 'dhl' : 'colisPrive';
		}
		return shippingMethodLocalStorage;
	}, [countryInfo]);

	const FieldItem = ({label, withLabel = false, value, icon}) => (
		<div className={classes.fieldItem}>
			<div className={classes.fieldIcon}>{icon}</div>
			{ withLabel && <div className={classes.fieldLabel}>{label} : </div> }
			<div className={classes.fieldValue}>{value}</div>
		</div>
	);

	const orderInfoItems = <div className={classes.orderDetails}>
		<FieldItem
			label='Client'
			value={getClientFullName(order)}
			icon={<PermContactCalendarIcon />}
		/>
		<FieldItem
			label='Email'
			value={order.get('email')}
			icon={<AlternateEmailIcon color='secondary' />}
		/>
		<FieldItem
			label='Adresse'
			value={getClientAddress(order)}
			icon={<HouseIcon color='primary' />}
		/>
	</div>;

	//---------------------------------------------------------//
	//-------------------- label printing ---------------------//
	//---------------------------------------------------------//
	/**
	 * print label
	 * @param shippingMethod
	 * @returns {Promise<void>}
	 * @private
	 */
	const _printLabel = async (shippingMethod) => {
		const data = { orderId: order.id, shippingMethod: getShippingMethodKey(shippingMethod), toConfirm: true };

		setPrintInProgress(true);

		// open the pdf in the iframe
		downloadFile({ fileName: 'exportOrderLabel', data, currentUser, windowLocation: 'print' });
	};

	//---------------------------------------------------------//
	//----------------- dispatching order ---------------------//
	//---------------------------------------------------------//
	const _confirm = async(values) => {
		//-- printing label --//
		await _printLabel(values.shippingMethod);

		//-- dispatching order --//
		await dispatch(dispatchOrder(values));

		if (values.useLastShippingMethod) {
			saveLastShippingMethod(values.shippingMethod);
		} else {
			clearLastShippingMethod();
		}

		setPrintInProgress(false);
		showScanPage();
	};
	const _submit = () => dispatch(submit('expeditionForm'));

	const _setStateAsShipping = async() => {
		if (order) {
			dispatch(setStateAsShipping(order.id));
			showHome();
		}
	}

	//---------------------------------------------------------//
	//------------------------- render ------------------------//
	//---------------------------------------------------------//
	return (
		<div>
			<CustomCard
				title={'Expédier la commande ' + orderId}
				content={
					<div className={classes.root}>
						{orderInfoItems}

						<ExpeditionForm
							onSubmit={_confirm}
							initialValues={{
								shippingMethod: lastShippingMethod || 'dhl',
								useLastShippingMethod: !!lastShippingMethod
							}}
							useLastShippingMethod={!!lastShippingMethod}
							className={classes.form}
						/>
					</div>
				}
				actions={
					<div className={classes.actionButtons}>
						<Button
							color='primary'
							onClick={_setStateAsShipping}
						>
							Marquer comme expédiée
						</Button>

						<div className={classes.usualButtons}>
							<Button
								color='secondary'
								onClick={showHome}
							>
								Annuler
							</Button>

							<Button
								color='primary'
								onClick={printInProgress ? undefined : _submit}
								classes={{root: classes.okBtn}}
							>
								Confirmer l'envoi
							</Button>
						</div>

					</div>
				}
			/>
		</div>
	);
};

export default ExpeditionPage;