import moment from 'moment';
import 'moment/locale/fr';
import Joi from 'joi-browser';
import Parse from 'parse';

//--------------------------------------------------------//
//----------------------- ENV ----------------------------//
//--------------------------------------------------------//
const ENV = (function() {
	const hostName = window.location.hostname;
	if (hostName.indexOf('bo.coovz.com') >= 0) {
		return 'PROD';
	} else if (hostName.indexOf('bo-preprod.coovz.com') >= 0) {
		return 'PREPROD';
	}
	return 'LOCAL';
})();

/**
 * get environment of project
 * @returns {string}
 */
export function getEnv() {
	return ENV;
}

/**
 * get parse serverUrl
 * @param [forceProd]
 * @returns {string}
 */
export function getParseServerUrl(forceProd = false) {
	const env = forceProd ? 'PROD' : getEnv();

	if (env === 'LOCAL') {
		return window.location.protocol + '//' + window.location.hostname + ':1337/parse';
	} else if (env === 'PREPROD') {
		return 'https://bo-preprod.coovz.com/parse';
	} else { // PROD
		return 'https://bo.coovz.com/parse';
	}
}

/**
 *
 * @param password
 * @param errors (optional)
 * @param name (default : 'password') the name of the field in errors
 * @returns {String | Object} if errors is passed, it returns it, otherwise it returns the eventual error message
 */
export function validatePassword(password, errors, name = 'password') {
	//---- errorMessage ----//
	let errorMessage;
	if (password == null || password.length < 8) {
		errorMessage = "Votre mot de passe doit faire au moins 8 caractères.";
	}

	//---- errors ----//
	if (errors) {
		if (errorMessage) {
			errors[name] = errorMessage;
		}
		return errors;
	} else {
		return errorMessage;
	}
}

//--------------------------------------------------------------------//
//----------------------- Parsers/Formatter --------------------------//
//--------------------------------------------------------------------//
/**
 * boolean to string
 * @param booleanValue
 * @returns {string}
 */
export function booleanFormatter(booleanValue) {
	return !!booleanValue ? "true" : "false";
}

/**
 * string to boolean
 * @param value
 * @returns {boolean}
 */
export function toBoolean(value) {
	return typeof value === "boolean" ? value : value === "true";
}

export function toMoment(momentOrDateOrString) {
	if (momentOrDateOrString == null) {
		return moment();
	} else if (moment.isMoment(momentOrDateOrString)) {
		//---- moment ----//
		return momentOrDateOrString;
	} else if (typeof momentOrDateOrString === 'string') {
		//---- string ----//
		return moment(momentOrDateOrString, 'YYYY-MM-DD');
	} else {
		//---- date ----//
		return moment(momentOrDateOrString);
	}
}

export const datePickerFormat = 'yyyy-MM-DDThh:mm';

export function getDayName(dateOrString, capitalized = true) {
	const date = toDate(dateOrString);
	let day = moment(date).format('dddd');
	capitalized && (day = capitalizeFirstLetter(day));
	return day;
}

export function getMonthName(dateOrString, capitalized = true) {
	const date = toDate(dateOrString);
	let month = moment(date).format('MMMM');
	capitalized && (month = capitalizeFirstLetter(month));
	return month;
}

export function formatDate(dateOrString, withDayName = true, withYear = true) {
	if (!dateOrString) {
		return '';
	}
	const date = toDate(dateOrString);

	const dayAndMonth = date.getDate() + (date.getDate() === 1 ? 'er' : '') + ' ' + getMonthName(date, false);

	//---- parts construction ----//
	const parts = [];
	withDayName && parts.push(getDayName(date));
	parts.push(dayAndMonth);
	withYear && parts.push(date.getFullYear());

	return parts.join(' ');
}

export function getLast3Month() {
	const last3Month = toMoment().subtract(3, 'month');
	return toDateFormatString(last3Month);
}

export function toIndexFormatString(momentOrDateOrString) {
	return toMoment(momentOrDateOrString).format('YYYYMMDD');
}
export function toDateFormatString(momentOrDateOrString) {
	return toMoment(momentOrDateOrString).format('YYYY-MM-DD');
}
export function toShortDateString(momentOrDateOrString, fullYear = false) {
	const format = fullYear ? 'DD/MM/YYYY' : 'DD/MM/YY';
	return toMoment(momentOrDateOrString).format(format);
}
export function toFrDateString(momentOrDateOrString, withSlash = false) {
	const strFormat = withSlash ? 'DD/MM/YYYY' : 'DD MMMM YYYY';
	return toMoment(momentOrDateOrString).format(strFormat);
}
export function toFrDateTimeString(momentOrDateOrString, withDoubleDots = true, withPreposition = false) {
	let dateTimeStr = toMoment(momentOrDateOrString).format('DD/MM/YY - HH:mm');
	if (!withDoubleDots) {
		dateTimeStr = dateTimeStr.replace(':', 'h');
	}
	if (withPreposition) {
		dateTimeStr = dateTimeStr.replace('-', 'à');
	}
	return dateTimeStr;
}
export function toLongDateString(momentOrDateOrString) {
	return toMoment(momentOrDateOrString).format('dddd DD MMMM YYYY');
}
export function toTimeString(momentOrDateOrString) {
	return toMoment(momentOrDateOrString).format('HH:mm');
}


export function toDate(momentOrDateOrString) {
	if (momentOrDateOrString == null) {
		return undefined;
	} else if (typeof momentOrDateOrString === 'string') {
		let [year, month, day] = momentOrDateOrString.split("-");
		if (momentOrDateOrString.includes('/')) {
			// french date DD/MM/YY
			[day, month, year] = momentOrDateOrString.split("/");
		}
		return new Date(year, month - 1, day);
	} else if (moment.isMoment(momentOrDateOrString)) {
		return momentOrDateOrString.toDate();
	} else {
		return momentOrDateOrString;
	}
}

/**
 * to check if the selected date is less then limit date
 * @param {date | String} date
 * @param {date | String} [limitDate]
 */
export function isOutOfDate(date, limitDate) {
	if (!date) return false;
	return toDateFormatString(date) < toDateFormatString(limitDate);
}

export function randomString() {
	return Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
}
//--------------------------------------------------------------------//
//------------------------- normalization ----------------------------//
//--------------------------------------------------------------------//
export function normalizeTo3Digits(value) {
	if (!value) return null;
	const onlyNums = value.replace(/[^\d]/g, '');
	// max 3 numbers
	if (onlyNums.length <= 3) {
		return toInt(onlyNums);
	}
	const newVal = onlyNums.slice(0, 3);
	return normalizeTo3Digits(newVal);
}

/**
 * convert string to number
 * @param {string | number} value
 * @returns {number}
 */
export function toNumber(value) {
	if (!value) {
		return 0;
	} else {
		if (typeof value === "number") return value;
	}
	const onlyNums = value.replace(/[^\d]/g, '');
	return toInt(onlyNums);
}

/**
 * to int number
 * @param value
 * @returns {number}
 */
export function toInt(value) {
	return value ? parseInt(value, 10) : 0;
}

/**
 * to decimal number
 * @param value
 * @returns {number}
 */
export function toDecimal(value) {
	if (!value) {
		return 0;
	} else {
		if (typeof value === "number") return parseFloat(value.toFixed(2));
	}
	// replace ',' to '.'
	const str = value.includes(',') ? value.replace(',', '.') : value;
	const res = parseFloat(str);
	if (!isNaN(res)) {
		return toDecimal(res); // to get a good format decimal
	}
	return 0;
}

/**
 * get french format for decimal
 * @param price
 * @param currency
 * @returns {string} like 78,90
 */
export function toFrFormatStrWithComma(price, currency = '€') {
	if (!price) return '';
	const priceStr = typeof price === 'number' ? price.toFixed(2) : price;
	const parts = priceStr.split('.');
	if (parts.length > 1) {

		const partsOne = parts[1] === '00' ? '' : parts[1];
		// add '0' at the end if one digit into centime
		const formattedPartsOne = partsOne.length === 1 ? partsOne + '0' : partsOne;
		return parts[0] + (formattedPartsOne.length ? ',' : '') + formattedPartsOne + ' ' + currency;
	} else {
		return price + ' ' + currency;
	}
}

/**
 * remove empty value
 * @param object
 * @returns {*}
 */
export function removeEmptyValues(object) {
	if (!object) return null;
	for (const key in object) {
		if (!object.hasOwnProperty(key)) {
			continue;
		}
		const value = object[key];
		if (isNull(value)) {
			delete object[key];
		}
	}
	return object;
}

/**
 * check if it's null ( 0, '', null, undefined, {}, [] )
 * @param item
 * @returns {boolean}
 */
export function isNull(item) {
	// NOTE : typeof null = 'object', typeof undefined = 'undefined'
	// see Loose Equality Comparisons With == at ( https://www.sitepoint.com/javascript-truthy-falsy )
	const typeOfValue = typeof item;
	switch (typeOfValue) {
		case 'string':
			return item.trim() === '';
		case 'object':
			return Object.is(item, null) || Object.values(item).every(val => isNull(val));
		case 'number':
			return !item;
		default:
			return item == null;
	}
}
//--------------------------------------------------------------------//
//----------------------------- Misc ---------------------------------//
//--------------------------------------------------------------------//
export function sort(array, keySupplier) {
	array.sort((item1, item2) => {
		const item1Key = keySupplier(item1);
		const item2Key = keySupplier(item2);
		if (item1Key < item2Key) return -1;
		if (item1Key > item2Key) return 1;
		return 0;
	});
	return array;
}

export function sortDesc(array, keySupplier = val => val) {
	array.sort((item1, item2) => {
		const item1Key = keySupplier(item1);
		const item2Key = keySupplier(item2);
		return item2Key - item1Key;
	});
	return array;
}

export function first(array) {
	return (array && array.length) ? array[0] : null;
}

export function remove(array, itemOrFunction) {
	let i;
	if (typeof itemOrFunction === "function") {
		i = array.findIndex(itemOrFunction);
	} else {
		i = array.indexOf(itemOrFunction);
	}
	if (i !== -1) {
		removeIndex(array, i);
		return true;
	}
	return false;
}
export function removeIndex(array, index) {
	array.splice(index, 1);
}
export function insert(array, index, item) {
	array.splice(index, 0, item);
}

export function clone(instance) {
	return Object.assign(Object.create(instance), instance);
}

/**
 * @param object
 * @param {array|Set} names
 * @returns {*}
 */
export function filter(object, names) {
	return Object.keys(object)
		.filter(key => names.has ? names.has(key) : names.includes(key))
		.reduce((obj, key) => {
			obj[key] = object[key];
			return obj;
		}, {});
}

/**
 * other functions at http://2ality.com/2014/10/es6-promises-api.html
 * @param ms
 * @returns {Promise}
 */
export function delay(ms) {
	return new Promise(resolve => {
		setTimeout(resolve, ms);
	});
}

//to capitalize only first letter
export function capitalizeFirstLetter(string) {
	if (!string || typeof string !== 'string' || (string && string.trim().length === 0)) return '';
	return string.charAt(0).toUpperCase() + string.slice(1);
}
//to capitalize all first letter of each word
export function capitalizeCase(string) {
	if (!string) {
		return '';
	}
	string = string.trim(); // important
	if (!string.length) {
		return '';
	}
	return string.toLowerCase().split(' ').map(word =>
		word[0].toUpperCase() + word.substr(1)
	).join(' ');
}
//to uppercase
export function uppercase(str) {
	if (!str || typeof str !== 'string') return '';
	return str.toUpperCase();
}
//to lowercase
export function lowercase(str) {
	if (!str || typeof str !== 'string') return '';
	return str.toLowerCase();
}

export function isIncluded(itemOrStr, text) {
	if (!itemOrStr || !text) return false;
	return itemOrStr.toString().toLowerCase().includes(text.toLowerCase());
}

export function getKeyValue(object, value) {
	if (!object) return value;
	let keyValue = value;
	Object.keys(object).forEach(key => {
		if (object[key].toLowerCase() === value.toLowerCase()) {
			keyValue = key;
		}
	});
	return keyValue;
}

export function shallowEquals(a, b) {
	for (let i in a) if (!(i in b)) return false;
	for (let i in b) if (a[i] !== b[i]) return false;
	return true;
}

/**
 * check if text is empty or null
 * @param string
 * @returns {boolean}
 */
export function isTextEmpty(string) {
	if (string) {
		string = string.trim();
		return !string.length;
	} else {
		return true;
	}
}
//--------------------------------------------//
//------------------- Forms ------------------//
//--------------------------------------------//
export function getErrorMessage(fieldName) {
	if (!fieldName) return null;
	switch (fieldName.toString()) {
		case 'firstName':
			return "Saisissez votre prénom";
		case 'lastName':
			return "Saisissez votre nom";
		case 'email':
		case 'username':
			return "Saisissez une adresse e-mail";
		case 'password':
			return "Choisissez votre mot de passe";
		case 'confirmPassword':
			return "Confirmez votre mot de passe";
		case 'address':
		case 'address2':
			return "Saisissez une adresse complète";
		case 'zipCode':
		case 'code':
			return "Saisissez les 5 chiffres de code postal";
		case 'city':
			return "Saisissez le nom de la ville";
		case 'phone':
			return "Saisissez un numéro téléphone valide.";
		case 'phoneNumber':
			return "Saisissez plutôt un numéro de mobile.";
		default:
			break;
	}
}

const errorLanguage = {
	any: {
		invalid: '!! Champ invalide',
		empty: '!! Ce champ ne peut pas être vide',
		required: '!! Champ obligatoire',
		allowOnly: '!! La valeur ne correspond pas',
	},
	string: {
		base: '!! Mauvais format',
		min: '!! Doit contenir au moins {{limit}} caractères',
		email: '!! Adresse email invalide',
		regex: {
			base: '!! Mauvais format'
		},
		required: '!! Champ obligatoire',
		creditCard: '!! Carte de crédit invalide'
	},
	number: {
		base: '!! Doit être un nombre',
		min: '!! Doit contenir au moins {{limit}} chiffres'
	}
};

export function validateValues({ values, schema, errors = {} }) {
	const result = Joi.validate(values, schema, {
		abortEarly: false,
		language: errorLanguage
	});
	if (result.error) {
		result.error.details.forEach(error => {
			errors[error.path] = getErrorMessage(error.path) || error.message;
		})
	}
	// TODO remove it when joi 10.7 is released
	if (values.email && !errors.email) {
		if (!isValidEmail(values.email)) {
			errors.email = "Saisissez un e-mail valide.";
		}
	}

	//--- zipCode ----------------
	if (values.zipCode) {
		// zipCode into AddressForm is retrieved from zipCodeForm
		if (!isValidZipCode(values.zipCode)) {
			errors.zipCode = getErrorMessage('zipCode');
		} else if (errors.zipCode) {
			// sometimes zipCode is checked outside, and there is an error but it shouldn't exist
			delete errors.zipCode;
		}
	}

	//---------- phoneNumber validation ----------//
	if (values.phoneNumber && !errors.phoneNumber) {
		if (!isValidPhoneNumber(values.phoneNumber)) {
			errors.phoneNumber = getErrorMessage('phoneNumber');
		}
	}
	
	return errors;
}

//----- email validation -----//
export const regexEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
export function isValidEmail(email) {
	return email.match(regexEmail);
}

//----- zip code validation ------ //
export function isValidZipCode(zipCodeStr) {
	const regexZipCode = new RegExp(/^[0-9]{5}$/);
	zipCodeStr = zipCodeStr ? zipCodeStr.trim() : '';
	return zipCodeStr.match(regexZipCode);
}

//----- phoneNumber validation -----//
export const regexPhoneNumber = new RegExp(/^(0[6-7]\d{8})$/);
export function isValidPhoneNumber(phoneNumber) {
	const phoneDigits = phoneNumber ? phoneNumber.trim().split(' ') : [];
	const phoneStr = phoneDigits.join('');
	return phoneStr.match(regexPhoneNumber);
}

export function normalizePhoneNumber(value) {
	if (!value) {
		return value;
	}
	const onlyNums = value.replace(/[^\d]/g, '');
	return sliceNumber(onlyNums);
}

function sliceNumber(number, step = 2) {
	if (!number) return number;
	const parts = [];
	for (let i = 0, j = 0; i < number.length; i += step, j++) {
		parts[j] = number.slice(i, i + step);
	}
	return parts.join(' ');
}

//----- query combination -----//

export class ComparisonQuery {
	static greaterThanIfExists({ query, property, value, strict = false }) {
		const operator = strict ? 'greaterThan' : 'greaterThanOrEqualTo';
		return createComparisonQuery({ query, property, value, operator });
	}

	static lessThanIfExists({ query, property, value, strict = false }) {
		const operator = strict ? 'lessThan' : 'lessThanOrEqualTo';
		return createComparisonQuery({ query, property, value, operator });
	}

	static containsIfExists({ query, property, value }) {
		return createComparisonQuery({ query, property, value, operator: 'contains' });
	}

	static equalToIfExists({ query, property, value }) {
		return createComparisonQuery({ query, property, value, operator: 'equalTo' });
	}
}

function createComparisonQuery({ query, property, value, operator }) {
	const doesNotExistsQuery = new Parse.Query(query.className).doesNotExist(property);
	const operatorQuery = new Parse.Query(query.className)[operator](property, value);
	return Parse.Query.or(doesNotExistsQuery, operatorQuery);
}

export function overlappedIntervalQuery({ query, propertyStart, propertyEnd, startValue, endValue }) {
	const fieldStartQuery = new Parse.Query(query.className)
		.lessThanOrEqualTo(propertyStart, startValue)
		.greaterThanOrEqualTo(propertyEnd, startValue);
	const fieldEndQuery = new Parse.Query(query.className)
		.lessThanOrEqualTo(propertyStart, endValue)
		.greaterThanOrEqualTo(propertyEnd, endValue);
	const bothFieldsQuery = new Parse.Query(query.className)
		.greaterThanOrEqualTo(propertyStart, startValue)
		.lessThanOrEqualTo(propertyEnd, endValue);

	return Parse.Query.or(fieldStartQuery, fieldEndQuery, bothFieldsQuery);
}

//---------------- for puppeteer ----------------//
export function toOneText(text) {
	if (text == null) {
		return undefined;
	}
	const value = text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
	return value.replace(/ /g, "_").replace("'", "_").replace(",", "").toLowerCase();
}

/**
 * generate char code for example picking location
 * @param {*} index 
 * @param {*} prefixCharCode 
 */
export function generateCharCode(index = 0, prefixCharCode) {
	const prefix = prefixCharCode ? String.fromCharCode(prefixCharCode) : '';
	// 'A' = 65,... , 'Z' = 90
	return prefix.concat(String.fromCharCode(65 + index));
}

/**
 * copy a string to a clipboard
 * @param str
 */
export function copyToClipBoard(str) {
	const el = document.createElement('textarea');
	el.value = str;
	document.body.appendChild(el);
	el.select();
	document.execCommand('copy');
	document.body.removeChild(el);
}

export function setLoading(type = 'start') {
	return async dispatch => {
		dispatch({
			type: type === 'start' ? "LOADING_START" : "LOADING_END"
		});
	}
}