import { browserHistory } from 'react-router';
import equal from 'fast-deep-equal';
import { toId } from '../parseUtils';

import { showError } from './app';

const { push : routerPush, goBack : goBackInRouter } = browserHistory;

/**
 * returns a thunk
 * @param {function(*=, *=): Promise<void>} thunkOrPromise signature if thunk: (dispatch : func, getState : func) : Promise
 * @returns {function(*=, *=): Promise<void>}
 */
export function actionWithLoader(thunkOrPromise) {
	return async (dispatch, getState) => {
		dispatch({
			type: "LOADING_START"
		});
		try {
			if (typeof thunkOrPromise === 'function') {
				await thunkOrPromise(dispatch, getState);
			} else {
				await thunkOrPromise;
			}
		} catch(error) {
			showError(error)(dispatch);
		} finally {
			dispatch({
				type: 'LOADING_END'
			});
		}
	}
}

export function onEnter({store, actionThunk, getReplacingPath, withLoader = true}) {
	return async (nextState, replace, callback) => {
		try {
			if (getReplacingPath) {
				const replacingPath = await getReplacingPath(store.getState);
				if (replacingPath) {
					replace(replacingPath);
					callback();
					return;
				}
			}

			//---- actual call ----//
			const dispatchingFunction = actionThunk(nextState.params);
			let result;
			if (withLoader) {
				result = actionWithLoader(dispatchingFunction)(store.dispatch, store.getState);
			} else {
				result = dispatchingFunction(store.dispatch, store.getState);
			}
			if (result && result.then) {
				await result;
			}

			callback();
		} catch(error) {
			console.error(error);
			callback(error);
		}
	}
}


export function goBack() {
	goBackInRouter();
}

export function routerLocation(pathname, returnPath) {
	if (typeof returnPath !== 'string') {
		// can be an Event (eg: onClick={showxxx})
		returnPath = undefined;
	}
	return {
		pathname,
		state: {
			returnPath
		}
	};
}

export function push(pathname, returnPath) {
	// pathname might already be a location object
	const location = returnPath ? routerLocation(pathname, returnPath) : pathname;
	routerPush(location);
}

export function showParseObj(urlPrefix, parseObjOrId, subPage) {
	const id = toId(parseObjOrId);
	return push('/' + urlPrefix + '-' + id + (subPage ? "/" + subPage : ""));
}
export function editParseObj(urlPrefix, parseObjOrId) {
	const id = toId(parseObjOrId);
	return push('/' + urlPrefix + 'Edit-' + id);
}

export function saveParseObj(parseObject, {actionType, goBack = false, callback}) {
	return actionWithLoader(async dispatch => {
		await parseObject.save();
		//---- resultActionType ----//
		actionType && dispatch({
			type: actionType
		});
		//---- goBackAfterwards ----//
		goBack && dispatch(goBackInRouter());
		//---- callback ----//
		callback && callback();
	});
}

export function setValueParseObj(parseObject, values) {
	const oldJson = parseObject.toJSON();
	Object.entries(values).forEach(([key, value]) => {
		const oldValue = oldJson[key];
		if (!equal(oldValue, value)) {
			parseObject.set(key, value);
		}
	});
}

export function getValueParseObj(parseObject) {
	return parseObject.toJSON();
}

/**
 * get url for CLIENT application
 * @returns {string}
 */
export function getPublicURL() {
	if (window.LOCAL) {
		//---- LOCAL ----//
		return `http://localhost:${window.location.port}`;
	} else if (window.PRODUCTION) {
		//---- PROD ----//
		return 'https://bo.coovz.com';
	} else {
		//---- PREPROD ----//
		return 'http://bo-preprod.coovz.com';
	}
}