import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import ReduxFormTextField from '../reduxFormMUIWrapper/ReduxFormTextField';

const useStyles = makeStyles(theme => ({
	subContentLabel: {
		marginLeft: 15
	},
	subContentValue: {
		marginLeft: '-15px'
	}
}));

const FormField = (props) => {
	// props
	const {
		label,
		rootClassName, labelClassName, valueClassName, containerClassName,
		fullWidth, horizontal = false, subContent = false, labelPosition = 'before',
		component, suffix = '', children, ...otherProps
	} = props;

	// styles
	const classes = useStyles(props);

	const { name, type, step } = otherProps;
	useEffect(() => {
		if (type === 'number' && step) {
			// NOTE : step is not directly recognized into otherProps
			const currentInput = `input[name="${name}"]`;
			const inputEl = document.querySelector(currentInput);
			if (inputEl) {
				inputEl.setAttribute('step', step);
				inputEl.setAttribute('lang', 'en-150');
			}
		}
	}, [type, step, name]);

	const usedComponent = component || ReduxFormTextField;

	return (
		<FormControl margin="normal" fullWidth={fullWidth} classes={{root: rootClassName}}>
			<div className={classNames(
					horizontal ? 'flexRow center' : 'flexColumn',
					subContent && classes.subContentLabel,
					containerClassName
				)}
			>
				{ labelPosition === 'before' && <FormLabel classes={{root: labelClassName}}>{label}</FormLabel> }
				<Field
					fullWidth={fullWidth}
					component={usedComponent}
					{...otherProps}
					className={classNames(subContent && classes.subContentValue, valueClassName)}
					InputProps={{
						endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>,
					}}
				>
					{ children }
				</Field>
				{ labelPosition === 'after' && <FormLabel classes={{root: labelClassName}}>{label}</FormLabel> }
			</div>
		</FormControl>
	);
};

FormField.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	rootClassName: PropTypes.string,
	labelClassName: PropTypes.string,
	valueClassName: PropTypes.string,
	containerClassName: PropTypes.string,
	horizontal: PropTypes.bool, // row is already taken by material-ui's FormGroup
	fullWidth: PropTypes.bool,
	subContent: PropTypes.bool,
	component: PropTypes.any,
	suffix: PropTypes.string,
	labelPosition: PropTypes.string, // it can be 'before' or 'after'
};

export default FormField;