import React from 'react';
import Responsive from 'react-responsive';

//---- Tags for JSX ----//
export const Mobile = props => <Responsive {...props} maxWidth={600} />;
export const MobileTablet = props => <Responsive {...props} maxWidth={1023} />;
export const Tablet = props => <Responsive {...props} minWidth={601} maxWidth={1023} />;
export const TabletDesktop = props => <Responsive {...props} minWidth={601} />;
export const Desktop = props => <Responsive {...props} minWidth={1024} />;

//---- media queries for css-js ----//
export const mobile = '@media (max-width: 600px)';
export const tablet = '@media (min-width: 601px) and (max-width: 1023px)';
export const mobileTablet = '@media (max-width: 1023px)';
export const desktop = '@media (min-width: 1024px)';

/**
 * has the advantage of being synchronous (unlike when we use redux)
 * @returns {string}
 */
export function getCurrentScreen() {
	let displayScreen = 'desktop';
	const width = window.innerWidth;
	// display screen for mobile
	if (width <= 600) {
		displayScreen = 'mobile';
	}
	// display screen for tablet
	if (width > 600 && width < 1024) {
		displayScreen = 'tablet';
	}

	return displayScreen;
}

/*------ page header height -------*/
// for desktop
export const HEADER_HEIGHT = 67;
// for mobile
export const MOBILE_HEADER_HEIGHT = 60;