import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button/Button';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import ShippingIcon from '@material-ui/icons/LocalShippingRounded';
import HelpIcon from '@material-ui/icons/ContactSupport';
import TimerIcon from '@material-ui/icons/Timer';
import UserIcon from '@material-ui/icons/AccountCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import DigitalClock from '../components/DigitalClock';

import { showHome, showLogin, showSearchPage, showScanPage, showHelpPage, } from '../actions/app';

const useStyles = makeStyles(theme => ({
	container: {
		composes: 'flexRow center',
	},
	root: {
		composes: '$container spaceBetween',
		backgroundColor: theme.background.grey[900],
		color: theme.color.white,
		minHeight: 40,
		padding: '5px 10px',
		zIndex: 1
	},
	leftBloc: {
		composes: '$container flex1',
		maxWidth: '65vw'
	},
	rightBloc: {
		composes: '$container',
	},
	homeBtn: {
		minWidth: 200,
		cursor: 'pointer'
	},
	clock: {
		composes: '$container',
		marginLeft: 15
	},
	userBtnContainer: {
		composes: '$container',
		minWidth: 200
	},
	userBtn: {
		composes: '$container',
		width: '100%',
		cursor: 'pointer'
	},
	username: {
		composes: 'flex1',
		marginLeft: 10,
	},
	separator: {
		color: theme.color.white,
	},
	menuItem: {
		marginRight: theme.spacing(1.5),
	},
	link: {
		composes: 'flexRow center',
		color: theme.color.white,
		cursor: 'pointer'
	},
	icon: {
		marginRight: theme.spacing(1),
		width: 20,
		height: 20,
	},
}));

const AppHeader = (props) => {
	const { user, onClickUserButton } = props;

	// styles
	const classes = useStyles(props);

	if (!user) return null;

	const LinkMenu = ({label, icon, onClick}) =>
		<Link
			onClick={onClick}
			className={classes.link}
			underline='none'
			component='button'
		>
			{icon}
			<Typography>{label}</Typography>
		</Link>;

	return (
		<AppBar position='static' color='transparent'>
			<Toolbar className={classes.root}>
				<div className={classes.leftBloc}>
					<Breadcrumbs
						aria-label='breadcrumb'
						classes={{
							li: classes.menuItem,
							separator: classes.separator
						}}
						separator=''
					>
						<LinkMenu
							label='COOVZ'
							onClick={showHome}
							icon={<HomeIcon className={classes.icon} />}
						/>

						<LinkMenu
							label='Rechercher'
							onClick={showSearchPage}
							icon={<SearchIcon className={classes.icon} />}
						/>

						<LinkMenu
							label='Expédier'
							onClick={showScanPage}
							icon={<ShippingIcon className={classes.icon} />}
						/>

						<LinkMenu
							label='Aide'
							onClick={showHelpPage}
							icon={<HelpIcon className={classes.icon} />}
						/>
					</Breadcrumbs>

				</div>

				<div className={classes.rightBloc}>
					<div className={classes.clock}>
						<TimerIcon />
						<DigitalClock />
					</div>
				</div>

				<div className={classes.userBtnContainer}>
					<UserIcon />
					{ !!user &&
						<div
							className={classes.userBtn}
							onClick={onClickUserButton}
						>
							<Typography className={classes.username} color='primary'>{user.get('username')}</Typography>
							<ExpandMoreIcon />
						</div>
					}
					{ !user &&
						<Button
							color='primary'
							onClick={() => showLogin()}
						>
							Se connecter
						</Button>
					}

				</div>
			</Toolbar>
		</AppBar>

	)
};
export default AppHeader;