import { getData } from './index';

export default function orders (
	state = {
		order: null,
		orderLocalVersion: 0,
		stats: null,
		orders: null,
		ordersDispatchSummary: null,
		count: 0
	}, action) {
	switch (action.type) {
		case 'ORDER_LOADED':
			return {
				...state,
				order: action.order
			};
		case 'REMOVE_ORDER':
			return {
				...state,
				order: null
			};
		case 'ORDER_UPDATED':
			return {
				...state,
				order: action.order,
				orderLocalVersion: state.orderLocalVersion + 1
			};
		case 'STATS_LOADED':
			return {
				...state,
				stats: action.stats
			};
		case 'ORDERS_LOADED':
			return {
				...state,
				orders: action.orders
			};
		case 'ORDERS_DISPATCH_SUMMARY_LOADED':
			return {
				...state,
				ordersDispatchSummary: action.ordersDispatchSummary
			};
		case 'ORDERS_COUNT_LOADED':
			return {
				...state,
				count: action.count
			};
		case 'ORDERS_COUNT_CLEARED':
			return {
				...state,
				count: 0
			};
		case 'ORDERS_DISPATCH_SUMMARY_CLEARED':
			return {
				...state,
				ordersDispatchSummary: null
			};
		default:
			return state;
	}
}

//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getOrder(state, errorIfNotFound = false) {
	return getData(state, "orders.order", errorIfNotFound && "No order data found");
}
export function getStats(state, errorIfNotFound = false) {
	return getData(state, "orders.stats", errorIfNotFound && "No orders data found");
}
export function getOrders(state, errorIfNotFound = false) {
	return getData(state, "orders.orders", errorIfNotFound && "No orders data found");
}
export function getOrdersDispatchSummarySelector(state, errorIfNotFound = false) {
	return getData(state, "orders.ordersDispatchSummary", errorIfNotFound && "No orders data found");
}
export function getOrdersCountSelector(state, errorIfNotFound = false) {
	return getData(state, "orders.count", errorIfNotFound && "No orders data found");
}