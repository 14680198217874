import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

import FormField from '../../components/form/FormField';
import ReduxFormSelect from '../../components/reduxFormMUIWrapper/ReduxFormSelect';
import { STATES } from '../../constants';
import { COUNTRIES, EXTENDED_SHIPPING_METHOD_TYPES, PRODUCTS, extendedActiveShippingMethods, getDispatchOrdersInitialValues, getOrdersStateByField } from '../../actions/orders';

const validate = values => {
  const errors = {};
  if (!values.shippingMethod) {
    errors.shippingMethod = 'Veulliez selectionner une mode expédition';
  } 
  return errors;
}

const useStyles = makeStyles((theme) => ({
	root: {
		composes: 'flexRow spaceBetween center',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
		},
		[theme.breakpoints.up('md')]: {
			minWidth: 450,
		},
	},
	left: {
		composes: 'flexRow center',
		flex: 1,
		marginRight: 160,
		[theme.breakpoints.between('md', 'lg')]: {
			marginRight: 60,
		},
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			marginRight: 0,
		},
	},

	leftColumns: {
		flex: 1,
		marginLeft: 10,
		marginRight: 10,
		[theme.breakpoints.down('md')]: {
			alignSelf: 'stretch',
			marginLeft: 0,
			marginRight: 0,
		},
	},
	select: {
		'& > div': {
			width: '100% !important',
		}
	},
	shippingMethodSelect: {
		'& > div': {
			minWidth: 200,
		}
	}
}));

const DispatchOrdersForm = (props) => {
	// state
	const [selectedState, setSelectedState] = useState([]);
	// values to be change for each field change, 
	// filter the orders for each field change
	const [values, setValues] = useState(getDispatchOrdersInitialValues());

	// props
	const { handleSubmit, resetForm } = props;

	// styles
	const classes = useStyles(props);

	// dispatch
	const dispatch = useDispatch();

	useEffect(() => {
		if (resetForm) {
			setSelectedState([]);
		}

		return () => {
			setSelectedState([]);
		}
	}, [resetForm]);

	// date
	const _handleDateChange = (event, newValue) => {
		const date = new Date(newValue);
		const formValues = { ...values, date };
		
		setValues(formValues);
		dispatch(change('dispatchOrdersForm', 'date', date));
		dispatch(getOrdersStateByField(formValues));
	};

	// state
	const _handleStateChange = (event, newValue) => {
		setSelectedState(newValue);
		const formValues = { ...values, state: newValue };
		setValues(formValues);

		dispatch(getOrdersStateByField(formValues));
	};

	// other fields
	const _handleChange = (field) => (event, newValue) => {
		const formValues = { ...values, [field]: newValue };
		setValues(formValues);

		dispatch(getOrdersStateByField(formValues));
	};

	const _renderSelectItem = (selected) => {
		return selected.map(value => STATES[value]).join(', ');
	};

	return (
		<form onSubmit={handleSubmit} className={classes.root}>
			{/* --------- left --------- */}
			<div className={classes.left}>
				{/* ------ date ------ */}
				<div className={classes.leftColumns}>
					<FormField
						label="Date"
						name='date' // filter by dueDate
						type='date'
						onChange={_handleDateChange}
						valueClassName={classes.dateTimeField}
						containerClassName={classes.dateTimeField}
					/>
				</div>

				{/* ------ produit ------ */}
				<div className={classes.leftColumns}>
					<FormField
						label='Produit'
						name='products' // maybe it will be an array later
						component={ReduxFormSelect}
						selectRootClassName={classes.select}
						onChange={_handleChange('products')}
						fullWidth
					>
						{PRODUCTS.map((product, index) =>
								<MenuItem
									key={product.value + index}
									value={product.value}
								>
									{product.label}
								</MenuItem>
							)
						}
					</FormField>
				</div>

				{/* ------ pays ------ */}
				<div className={classes.leftColumns}>
					<FormField
						label='Pays'
						name='country'
						component={ReduxFormSelect}
						selectRootClassName={classes.select}
						onChange={_handleChange('country')}
						fullWidth
					>
						{COUNTRIES.map((country, index) =>
								<MenuItem
									key={country.value + index}
									value={country.value}
								>
									{country.label}
								</MenuItem>
							)
						}
					</FormField>
				</div>

			{/* --------- statut --------- */}
				<div className={classes.leftColumns}>
					<FormField
						label='Statut'
						name='state'
						multiple
						value={selectedState}
						onChange={_handleStateChange}
						component={ReduxFormSelect}
						renderValue={_renderSelectItem}
						selectRootClassName={classes.select}
						fullWidth
					>
						{Object.keys(STATES).map(key =>
							<MenuItem key={key} value={key}>
								<Checkbox checked={selectedState.indexOf(key) > -1} />
								<ListItemText primary={STATES[key]} />
							</MenuItem>
						)}
					</FormField>
				</div>
			</div>

			{/* --------- right --------- */}
			<div>
				<FormField
					label='Mode expédition'
					name='shippingMethod'
					component={ReduxFormSelect}
					selectRootClassName={classes.shippingMethodSelect}
				>
					{Object.keys(EXTENDED_SHIPPING_METHOD_TYPES)
						.map(type =>
							<MenuItem
								key={type}
								value={type}
								disabled={!extendedActiveShippingMethods.includes(type)}
							>
								{EXTENDED_SHIPPING_METHOD_TYPES[type]}
							</MenuItem>
						)
					}
				</FormField>
			</div>

			<input type='submit' />
		</form>
	)
};

DispatchOrdersForm.propTypes = {
	onSubmit: PropTypes.func,
	resetForm: PropTypes.bool
};
export default reduxForm({
	form: 'dispatchOrdersForm',
	validate
})(DispatchOrdersForm);