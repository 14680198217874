import Parse from 'parse';
import { getEnv } from '../utils';

import { actionWithLoader, onEnter, push } from './utils';
import { getDispatchOrdersDefaultDate, getOrdersStateByField, loadStatsThunk } from './orders';

import { getCurrentUser } from '../reducers/app';

const CONNECTION_FAILED = "Votre connexion semble dégradée, vérifiez-là puis actualisez la page.";
/**
 *
 * @param {object} error
 */
function handleErrorMessage(error) {
	switch (error.code) {
		case 100:
			return CONNECTION_FAILED;
		default:
			return error.message;
	}
}

export function showError(errorOrMessage) {
	return dispatch => {
		console.error(errorOrMessage);
		dispatch({
			type: "ERROR",
			message: typeof errorOrMessage === "string" ? errorOrMessage : handleErrorMessage(errorOrMessage)
		});
	}
}
export function closeError() {
	return { type: "CLOSE_ERROR" };
}

/**
 * load coupon feedback
 * @param {string} message
 * @param {string} [variant]
 */
export function showMessage(message, variant = 'info') {
	return dispatch => {
		dispatch({
			type: 'MESSAGE',
			message,
			variant
		});
	}
}

export function closeMessage() {
	return { type: "CLOSE_MESSAGE" };
}

export function login(username, password) {
	return actionWithLoader(async (dispatch, getState) => {
		await Parse.User.logIn(username, password);
		await loginSuccess()(dispatch, getState);
		showHome();
	});
}

export function logout() {
	return actionWithLoader(async dispatch => {
		await Parse.User.logOut();
		showLogin();
		dispatch({
			type: 'LOGOUT_SUCCESS'
		});
	})
}

export function loginSuccess() {
	return actionWithLoader(async (dispatch, getState) => {
		const currentUser = Parse.User.current() || getCurrentUser(getState());
		if (currentUser && currentUser.getSessionToken()) {
			dispatch({
				type: 'LOGIN_SUCCESS',
				user: currentUser
			});

			// update user into localStorage
			updateUserIntoLocalStorage(currentUser);

			// we go to home with we were on the login path
			// (not in case of an auto-login from index.js)
			if (window.location.pathname.endsWith('login')) {
				showHome();
			}
		} else {
			// retry login
			showLogin();
		}
	})
}

//---------------------------------------------------------//
//---------- fixing bug for Parse.User.Current() ----------//
//---------------------------------------------------------//
/**
 * Parse.User.Current() save an user as bad ParseUser into localStorage
 * see https://github.com/parse-community/Parse-SDK-JS/issues/992
 */
const currentUserPath = 'Parse/iota/currentUser';

/**
 * get currentUser from LocalStorage
 * @returns {Parse.Object}
 */
export function retrieveUserFromLocalStorage() {
	const userIntoLocalStorage = localStorage.getItem(currentUserPath);
	if (userIntoLocalStorage) {
		const userData = JSON.parse(userIntoLocalStorage);
		// see https://github.com/parse-community/Parse-SDK-JS/issues/992
		userData.className = '_User';
		return Parse.Object.fromJSON(userData);
	}
	return null;
}

/**
 * clear user into localStorage
 */
export function clearUserIntoLocalStorage() {
	localStorage.removeItem(currentUserPath);
}

/**
 * update currentUser into localStorage
 * @param user
 */
export function updateUserIntoLocalStorage(user) {
	if (!user) return null;

	// see updateUserOnDisk(user) .../node_modules/parse/lib/node/ParseUser.js
	const json = user.toJSON();
	delete json.password;
	json.className = '_User';
	const userData = JSON.stringify(json);
	localStorage.setItem(currentUserPath, userData);
}

//---------------------------------------------------------//
//---------------------- onEnterApp -----------------------//
//---------------------------------------------------------//
export function onEnterApp(store) {
	return async (nextState, replace, callback) => {
		try {
			const getState = store.getState;
			const user = getCurrentUser(getState());
			if (user) {
				await loadStatsThunk()(store.dispatch);
			}
			callback();
		} catch (error) {
			console.error(error);
			callback(error);
		}
	}
}

export function onEnterHome(store) {
	return onEnter({
		store,
		actionThunk: () => {
			return async (dispatch, getState) => {
				const user = getCurrentUser(getState());
				// load orders's count to treat
				const values = { date: getDispatchOrdersDefaultDate() };
				dispatch(getOrdersStateByField(values));
				if (user) {
					await loadStatsThunk()(store.dispatch);
				}
			}
		}
	});
}

export function onEnterUnknownRoute(store) {
	return async (nextState, replace, callback) => {
		try {
			const getState = store.getState;
			const user = getCurrentUser(getState());
			if (user) {
				showHome();
			} else {
				showLogin();
			}
			callback();
		} catch (error) {
			console.error(error);
			callback(error);
		}
	}
}

export function updateScreen(displayScreen) {
	return async dispatch => {
		dispatch({
			type: 'DISPLAY_SCREEN_UPDATED',
			displayScreen
		});
	}
}
//--------------------------------------------------------//
//---------------------- Routing -------------------------//
//--------------------------------------------------------//
export function downloadFile({ fileName, data = {}, currentUser, windowLocation = undefined }) {
	data.sessionToken = currentUser.getSessionToken();

	const serverUrl = getDownloadServerUrl();

	const location = serverUrl + '/' + fileName + '?'
		+ Object
		.keys(data)
		.map(value => `${value}=${encodeURIComponent(data[value])}`)
		.join('&');

	if (windowLocation) {
		return window.open(location, windowLocation);
	} else {
		window.location = location;
	}
}

/**
 * Returns the serverUrl used to download files
 * @returns {String}
 */
export function getDownloadServerUrl() {
	const env = getEnv();
	if (env === 'LOCAL') {
		return window.location.protocol + '//' + window.location.hostname + ':1337';
	} else {
		return window.location.origin;
	}
}
//--------------------------------------------------------//
//---------------------- Routing -------------------------//
//--------------------------------------------------------//
export function showHome() {
	return push('/');
}

export function showLogin() {
	return push('/login');
}

export function showScanPage() {
	return push('/scan');
}

export function showExpeditionPage() {
	return push('/expedition');
}

export function showSearchPage() {
	return push('/search');
}

export function showHelpPage() {
	return push('/help');
}