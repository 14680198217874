import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CustomCard from '../../components/CustomCard';

const useStyles = makeStyles(theme => ({
	root: {

	},
	button: {
		background: theme.color.yellow[586],
		border: 0,
		borderRadius: 3,
		boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
		color: 'white',
		height: 48,
		maxWidth: 200,
		padding: '0 30px',
	},
	item: {
		marginBottom: 15
	}
}));

const HelpPage = (props) => {
	// styles
	const classes = useStyles(props);

	const Item = ({title, content}) => (
		<div className={classes.item}>
			<Typography variant='h6' color='primary'>{title}</Typography>
			<div>
				{content}
			</div>
		</div>
	);

	return (
		<CustomCard
			title="Besoin d'aide ?"
			content={
				<div className={classes.root}>
					<Item
						title='Recherche de commande'
						content={
							<div>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit,
								sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
								Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
								Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
							</div>
						}
					/>

					<Item
						title='Expédition de commande'
						content={
							<div>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit,
								sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
								Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
								Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
							</div>
						}
					/>
				</div>
			}
			cancelLabel='Fermer'
		/>
	)
};
export default HelpPage;