import React from 'react';

import PropTypes from 'prop-types';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import { toFrDateTimeString } from '../../utils';
import { SHIPPING_METHOD_TYPES, showOrder, getTrackingNumberFromRow } from '../../actions/orders';

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);


const useStyles1 = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
}));

function TablePaginationActions(props) {
	const classes = useStyles1();
	const theme = useTheme();
	const { count, page, rowsPerPage, onChangePage } = props;

	const handleFirstPageButtonClick = (event) => {
		onChangePage(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onChangePage(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onChangePage(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<div className={classes.root}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</div>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onChangePage: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
	paginationButtons: {
		width: '100%'
	},
	orderId: {
		cursor: 'pointer'
	}
});

const OrderTables = (props) => {
	// props
	const { rows } = props;

	// styles
	const classes = useStyles(props);

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const _showOrder = (order) => {
		showOrder(order.objectId,true);
	};

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} size='small' aria-label='customized table'>
				<TableHead>
					<TableRow>
						<StyledTableCell>ID commande</StyledTableCell>
						<StyledTableCell>Nom et prénoms</StyledTableCell>
						<StyledTableCell>Statut</StyledTableCell>
						<StyledTableCell>Méthode d'expédition</StyledTableCell>
						<StyledTableCell>Date d'expédition</StyledTableCell>
						<StyledTableCell>Numéro de suivi</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{(rowsPerPage > 0
							? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							: rows
						).map((row) => (
						<StyledTableRow key={row.orderId}>
							<StyledTableCell component="th" scope="row">
								<div
									className={classes.orderId}
									onClick={() => _showOrder(row)}
								>
									{row.orderId}
								</div>
							</StyledTableCell>
							<StyledTableCell>{row.firstName + ' ' + row.lastName}</StyledTableCell>
							<StyledTableCell>{row.state === 'waiting' ? 'en attente' : 'expédiée'}</StyledTableCell>
							<StyledTableCell>{SHIPPING_METHOD_TYPES[row.shippingMethod]}</StyledTableCell>
							<StyledTableCell>{row.expeditionDate ? toFrDateTimeString(row.expeditionDate) : ''}</StyledTableCell>
							<StyledTableCell align="right">{getTrackingNumberFromRow(row)}</StyledTableCell>
						</StyledTableRow>
					))}

					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={6} />
						</TableRow>
					)}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[5, 10, 20, 25, {label: 'Toutes', value: -1}]}
							labelRowsPerPage='Commandes par page'
							colSpan={3}
							count={rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: {'aria-label': 'Lignes par page'},
								native: true,
							}}
							onChangePage={handleChangePage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
							classes={{root: classes.paginationButtons}}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
}
export default OrderTables;
